import {
  TextField,
  DateField,
  ReferenceField,
  NumberInput,
  DateTimeInput,
  SelectField,
  SelectInput,
  FunctionField,
  ReferenceInput,
  TextInput,
} from "react-admin";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import { MyList } from "../ra-lb-tools/components/myList";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";

export const openLockerTokenIcon = EnhancedEncryptionIcon;

const intentChoices = [
  { id: 1, name: "Dropoff" },
  { id: 2, name: "Pickup" },
  { id: 3, name: "Release" },
  { id: 4, name: "Emergency" },
];

const openLockerTokenFilters = [
  <NumberInput label="Locker Id" source="locker_id" alwaysOn key={1} />,
  <SelectInput
    label="Intent"
    source="intent"
    alwaysOn
    key={2}
    choices={intentChoices}
  />,
  <DateTimeInput label="Expires after" source="expires_at_gt" key={3} />,
  <TextInput
    label="Tower id"
    source="locker__tower_id"
    alwaysOn
    key="locker__tower_id"
  />,
  <ReferenceInput
    source="open-locker-tokens##user_id"
    label="User"
    reference="users"
    allowEmpty
    key="open-locker-tokens##user_id"
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
];

export const openLockerTokenList = (props) => {
  const resource = "open-locker-tokens";

  return (
    <MyList {...props} filters={openLockerTokenFilters} resource={resource}>
      <TextField source="id" />
      <ReferenceField
        source="lockerId"
        reference="lockers"
        link="show"
        label="Locker"
      >
        <FunctionField render={(record) => `${record.name} (#${record.id})`} />
      </ReferenceField>
      <ReferenceField
        source="userId"
        reference="users"
        sortable={false}
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <SelectField choices={intentChoices} source="intent" sortable={false} />
      <DateField source="expiresAt" showTime />
      <TextField source="clientInfo" sortable={false} />
      <TextField source="currentToken" sortable={false} />
      <TextField source="nextToken" sortable={false} />
    </MyList>
  );
};
