import { Box } from "@mui/material";
import { Debug } from "./debug";

export const MenuSpacer = ({ route, ...props }) => {
  const Spacer = (
    <Box
      {...props}
      className="menu-spacer"
      data-sort-order={route.options.sortOrder}
      style={{ padding: "21px" }}
    >
      <hr style={{ margin: 0, borderColor: "grey" }} />
    </Box>
  );

  return route.options.debug ? <Debug>{Spacer}</Debug> : Spacer;
};
