const normalizeContainer = (container) => ({
  x: container.x || container.pos?.x || 0,
  y: container.y || container.pos?.y || 0,
  width: container.width || container.size?.width || 0,
  height: container.height || container.size?.height || 0,
  padding: container.padding || 0,
});

export const coord = {
  x: {
    left: (container) => {
      container = normalizeContainer(container);
      return container.x + container.padding;
    },
    center: (container, size = { height: 0, width: 0 }) => {
      container = normalizeContainer(container);
      return container.x + (container.width - size.width) / 2;
    },
    right: (container, size = { height: 0, width: 0 }) => {
      container = normalizeContainer(container);
      return container.x + container.width - size.width - container.padding;
    },
  },
  y: {
    top: (container) => {
      container = normalizeContainer(container);
      return container.y + container.padding;
    },
    center: (container, size = { height: 0, width: 0 }) => {
      container = normalizeContainer(container);
      return container.y + (container.height - size.height) / 2;
    },
    bottom: (container, size = { height: 0, width: 0 }) => {
      container = normalizeContainer(container);
      return container.y + container.height - size.height - container.padding;
    },
  },
};
