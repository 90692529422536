export const statusChoices = [
  { id: 1, name: "Available", color: "success" },
  { id: 2, name: "Out of service", color: "warning" },
  { id: 3, name: "Pending", color: "info" },
  { id: 4, name: "Retired", color: "error" },
];

export const accessModeChoices = [
  { id: 1, name: "Public" },
  { id: 2, name: "Restricted" },
];
