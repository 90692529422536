import {
  TextField,
  TextInput,
  TabbedShowLayout,
  NumberField,
  NumberInput,
} from "react-admin";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { useParams } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import { Debug } from "../ra-lb-tools/components/debug";
import { LockerList } from "./lockers";
import { ProductList } from "./billing/products";
import { Settings } from "../ra-lb-tools/utils/settings";
import { Row } from "../ra-lb-tools/components/row";

export const LockerTypeIcon = BusinessIcon;

export const LockerTypeList = (props) => (
  <Debug>
    <MyList {...props} resource="locker-types">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="locker_provider_type" />
      <NumberField source="relWidth" />
      <NumberField source="relHeight" />
      <NumberField source="sortOrder" />
      <NumberField source="lockerCount" />
    </MyList>
  </Debug>
);

export const LockerTypeEdit = (props) => {
  return (
    <Debug>
      <Edit {...props}>
        <TextInput source="id" disabled />
        <TextInput source="name" />
        <TextInput source="description" multiline />
        <TextInput source="locker_provider_type" />
        <NumberInput source="relWidth" />
        <NumberInput source="relHeight" />
        <NumberInput source="sortOrder" />
      </Edit>
    </Debug>
  );
};

export const LockerTypeCreate = (props) => {
  return (
    <Debug>
      <Create {...props}>
        <TextInput source="name" />
        <TextInput source="description" multiline />
        <NumberInput source="relWidth" />
        <NumberInput source="relHeight" />
      </Create>
    </Debug>
  );
};

export const LockerTypeShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <Debug>
      <TitledShow>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Summary">
            <Row className={"RecordSummary"}>
              <LabeledColumn>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <NumberField source="sortOrder" />
              </LabeledColumn>
              <LabeledColumn>
                <NumberField source="relWidth" />
                <NumberField source="relHeight" />
                <NumberField source="lockerCount" />
              </LabeledColumn>
            </Row>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Lockers" path="lockers">
            <LockerList filter={{ type_id: id }} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Products" path="products">
            <ProductList filter={{ locker_type_id: id }} />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </TitledShow>
    </Debug>
  );
};

export const LockerTypeInput = (props) => {
  return <MyReferenceInput source="type" reference="locker-types" {...props} />;
};

export const LockerTypeField = (props) => {
  return (
    <MyReferenceField
      source="type"
      reference="locker-types"
      link={Settings.debugging() ? "show" : false}
      {...props}
    />
  );
};
