import { Card, CardContent } from "@mui/material";
import { Title, useGetIdentity } from "react-admin";
import { JsonStyled } from "../components/jsonStyled";
import pick from "lodash/pick";

export const CurrentUser = () => {
  const { data } = useGetIdentity();
  const info = {
    ...pick(data, ["id", "fullName"]),
    ...pick(data, [
      "decoded.email",
      "decoded.scope",
      "decoded.realm_access",
      "decoded.resource_access",
    ]).decoded,
  };

  return (
    <Card>
      <Title title="Current User" />
      <CardContent>
        <JsonStyled data={info} />
      </CardContent>
    </Card>
  );
};
