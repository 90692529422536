import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Grid,
  DialogTitle,
  Slide,
  Snackbar,
  Alert,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DayRangeModal = ({ open, onClose, onApply }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState(dayjs().hour(0).minute(0));
  const [endTime, setEndTime] = useState(dayjs().hour(12).minute(0));
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationOpen(false);
  };
  const handleApply = () => {
    if (selectedDays.length === 0 || startTime === "" || endTime === "") {
      setNotificationOpen(true);
    } else {
      onApply(selectedDays, startTime, endTime);
      onClose();
    }
  };
  const selectWeekdays = () => {
    setSelectedDays(["monday", "tuesday", "wednesday", "thursday", "friday"]);
  };

  const selectWeekend = () => {
    setSelectedDays(["saturday", "sunday"]);
  };

  const clearSelection = () => {
    setSelectedDays([]);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <DialogTitle>Select Days and Time Range</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={selectWeekdays}>
              Weekdays
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={selectWeekend}>
              Weekend
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={clearSelection}>
              Clear
            </Button>
          </div>
          <TextField
            label="Select Days"
            fullWidth
            variant="outlined"
            select
            SelectProps={{
              multiple: true,
              value: selectedDays,
              onChange: (e) => setSelectedDays(e.target.value),
            }}
          >
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => (
              <MenuItem key={day} value={day}>
                {day.charAt(0).toUpperCase() + day.slice(1)}{" "}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={6}>
                <TimePicker
                  label="Start Time"
                  fullWidth
                  variant="outlined"
                  type="time"
                  value={startTime}
                  onChange={(e) => {
                    setStartTime(e);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="End Time"
                  fullWidth
                  variant="outlined"
                  type="time"
                  value={endTime}
                  onChange={(e) => {
                    setEndTime(e);
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="warning"
          variant="filled"
        >
          Please select days and a valid time range.
        </Alert>
      </Snackbar>
    </>
  );
};

export default DayRangeModal;
