import { KonvaMuiIcon } from "./KonvaMuiIcon";
import { access_icon, status_icon } from "./lockerIcons";
import { Box } from "@mui/material";
import { Column } from "../../ra-lb-tools/components/column";
import { splitArray } from "../../ra-lb-tools/utils/tools";
import { Row } from "../../ra-lb-tools/components/row";
import { Accessible } from "@mui/icons-material";
import { styleSchema } from "./style";
import { coord } from "./coord";

const iconSize = 24;

const size = { width: iconSize, height: iconSize };

const LowLockerIcon = <Accessible />;
export const LowRender = ({ spec, renderProps, style }) => {
  renderProps = {
    ...renderProps,
    padding: styleSchema.iconPadding,
  };

  return spec.isLowLocker ? (
    <KonvaMuiIcon
      x={coord.x.right(renderProps, size)}
      y={coord.y.bottom(renderProps, size)}
      fill={style.iconColor}
      opacity={style.textOpacity ? style.textOpacity : null}
      MuiIcon={LowLockerIcon}
    />
  ) : null;
};

export const StatusRender = ({ spec, renderProps, style }) => {
  renderProps = {
    ...renderProps,
    padding: styleSchema.iconPadding,
  };

  let Icon = status_icon[spec.statusId]?.hideInDiagram
    ? null
    : status_icon[spec.statusId]?.icon;

  return Icon ? (
    <KonvaMuiIcon
      x={coord.x.left(renderProps, size)}
      y={coord.y.top(renderProps, size)}
      fill={style.iconColor}
      opacity={style.textOpacity ? style.textOpacity : null}
      MuiIcon={Icon}
    />
  ) : null;
};

export const AccessRender = ({ spec, renderProps, style }) => {
  renderProps = {
    ...renderProps,
    padding: styleSchema.iconPadding,
  };

  let Icon = access_icon[spec.accessModeId]?.icon;

  return Icon ? (
    <KonvaMuiIcon
      x={coord.x.left(renderProps, size)}
      y={coord.y.bottom(renderProps, size)}
      fill={style.iconColor}
      opacity={style.textOpacity ? style.textOpacity : null}
      MuiIcon={Icon}
    />
  ) : null;
};

export const Legend = (props) => {
  const icon_lists = splitArray(
    []
      .concat(Object.values(status_icon))
      .concat(Object.values(access_icon))
      .concat([{ label: "Low locker", icon: LowLockerIcon }]),
    3
  );

  const sx = {
    border: "1px solid black",
    padding: "5px",
    ...props.sx,
  };

  const titleSx = {
    textAlign: "center",
    color: "red",
    backgroundColor: "transparent",
    padding: "5px",
    fontSize: "1.2em",
  };

  return (
    <Box className={"Legend"} sx={sx}>
      <Box sx={titleSx}>Legend</Box>
      <Row>
        {icon_lists.map((icon_list, index) => (
          <Column key={index}>
            {icon_list.map((icon_setup, index) => (
              <Box key={index} sx={{ display: "flex" }}>
                <span style={{ minWidth: "24px" }}>
                  {icon_setup.hideInDiagram || icon_setup.icon}
                </span>
                : <span>{icon_setup.label}</span>
              </Box>
            ))}
          </Column>
        ))}
      </Row>
    </Box>
  );
};
