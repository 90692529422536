import Keycloak from "keycloak-js";
import { fetchUtils } from "react-admin";

const minTimeBeforeTokenRefresh = 5;

export const httpClient =
  (keycloak: Keycloak) => async (url: string, options: fetchUtils.Options) => {
    // If the token expires within `minTimeBeforeTokenRefresh` seconds, the token is refreshed. Else, use the same token
    await keycloak.updateToken(minTimeBeforeTokenRefresh);
    const requestHeaders = getKeycloakHeaders(keycloak.token, options);
    return await fetchUtils.fetchJson(url, {
      ...options,
      headers: requestHeaders,
    });
  };

export const getKeycloakHeaders = (
  token: string | null | undefined,
  options: fetchUtils.Options
): Headers => {
  const headers = ((options && options.headers) ||
    new Headers({
      Accept: "application/json",
    })) as Headers;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};
