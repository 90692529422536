import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toClipboard } from "../utils/tools";
import { IconButton } from "@mui/material";

export const CopyToClipboardButton = ({ data, sx, ...props }) => {
  return (
    <IconButton
      className="CopyToClipboardButton"
      onClick={() => toClipboard(data)}
      title="Copy to clipboard"
      style={{ backgroundColor: "lightgrey", ...sx }}
      size="small"
      {...props}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};
