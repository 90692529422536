import { useNotify } from "react-admin";
import { ClickToBuffer } from "./clickToBuffer";
import { debug_log } from "../utils/debugLog";

function endsWithStartOf(str1, str2, minMatch = 0) {
  const minOverlap = Math.min(str1.length, str2.length);

  for (let i = minOverlap; i > minMatch; i--) {
    if (str1.endsWith(str2.slice(0, i))) {
      return str2.slice(0, i);
    }
  }
  return false;
}

function endsWithStartOfAny(haystack, needles, minMatch = 0) {
  for (let index = 0; index < needles.length; index++) {
    const needle = needles[index];
    const intersection = endsWithStartOf(haystack, needle, minMatch);
    if (intersection) {
      return intersection;
    }
  }

  return false;
}

const codeSent = (
  buffer,
  callback,
  notify,
  commands,
  caseSensitive,
  notifyPartial
) => {
  let normalizedBuffer = caseSensitive ? buffer : buffer.toLowerCase();
  const commandList = Object.keys(commands);

  const selectedCommand = commandList.filter((commandKey) =>
    normalizedBuffer.includes(commandKey)
  )[0];

  if (selectedCommand) {
    debug_log(`Execute '${selectedCommand}'`);
    const command = commands[selectedCommand];
    command();
    callback(true);
    return "";
  }

  const partialMatch = endsWithStartOfAny(buffer, commandList, 2);
  if (partialMatch && notifyPartial) {
    notify(partialMatch, {
      autoHideDuration: 1800,
      multiLine: true,
      type: "success",
    });
  }

  callback(false);
  return buffer;
};

export const ClickToCommand = ({
  commands,
  caseSensitive,
  notifyPartial,
  ...props
}) => {
  const notify = useNotify();

  return (
    <ClickToBuffer
      processor={(buffer, callback) =>
        codeSent(
          buffer,
          callback,
          notify,
          commands,
          caseSensitive,
          notifyPartial
        )
      }
      {...props}
    />
  );
};

ClickToCommand.defaultProps = {
  commands: {},
  caseSensitive: false,
  notifyPartial: true,
};
