import get from "lodash/get";
import { useGetOne, WithRecord } from "react-admin";
import { MultiDateField } from "../../ra-lb-tools/components/multiDateField";

export const TowerTimezonedDate = ({ towerId, ...props }) => {
  const { data } = useGetOne("towers", { id: towerId });

  const timezones = get(data, "location.timezone")
    ? { UTC: "utc", LT: get(data, "location.timezone") }
    : null;

  return <MultiDateField {...props} timezones={timezones} />;
};

export const TowerTimezonedDateField = (props) => {
  return (
    <WithRecord
      render={(record) => (
        <TowerTimezonedDate {...props} towerId={record.towerId} />
      )}
    />
  );
};
