import { TextField } from "react-admin";
import { useParams } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyBooleanField } from "../components/myBooleanField";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";
import { Base64ToHexField } from "../ra-lb-tools/components/base64ToHexField";

export const SystemIcon = BusinessIcon;

export const LockerStateList = (props) => {
  const title = "Locker State";
  const resource = "lockerState";
  const lockerId = props.lockerId;

  const filter = props.filter || { lockerId };

  return (
    <MyList
      title={title}
      resource={resource}
      filter={filter}
      sort={{
        field: "id",
        order: "DESC",
      }}
    >
      <TextField source="id" />
      <MyBooleanField source="available" />
      <TextField source="keypadCode" />
      <MyBooleanField source="keypadCodePersists" />
      <Base64ToHexField source="lockerTokenB64" />
      <Base64ToHexField source="keypadTokenB64" />
      <MyReferenceField source="lockerId" reference="lockers" />
    </MyList>
  );
};

export const LockerStateListView = () => {
  const { lockerId } = useParams();

  return <LockerStateList lockerId={lockerId} />;
};
