import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const StatusInfoTooltip = () => {
  const icon = (
    <InfoIcon color="primary" style={{ marginLeft: 10, cursor: "pointer" }} />
  );

  const tooltip = (
    <dl>
      <dt>Available</dt>
      <dd>Active. Currently available for service</dd>
      <dt>Pending</dt>
      <dd>Expected to go live soon. Being set up for live service.</dd>
      <dt>Out of Service</dt>
      <dd>
        Temporarily OOS but expected to go back in service. Ex: Being repaired,
        moved, needing service by a tech, etc
      </dd>
      <dt>Retired</dt>
      <dd>Removed from service and not expected to go back into service.</dd>
    </dl>
  );

  return (
    <Tooltip
      title={tooltip}
      placement="right-end"
      className="StatusInfoTooltip"
    >
      {icon}
    </Tooltip>
  );
};

export const StatusHeader = () => {
  const fieldName = "Status";
  const style = {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  };

  return (
    <span style={style} className="StatusHeader">
      <span>{fieldName}</span>
      <StatusInfoTooltip />
    </span>
  );
};
