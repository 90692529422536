import { Box } from "@mui/material";
import { useRecordContext, useResourceContext } from "react-admin";
import inflection from "inflection";

const getResourceName = () =>
  inflection.titleize(inflection.singularize(useResourceContext() || ""));

const getRecordName = (labelField) => {
  const record = useRecordContext() || {};

  return (
    record[labelField] ||
    record["name"] ||
    record["email"] ||
    record["fullName"] ||
    "--"
  );
};

export const RecordTitle = ({ labelField, actions, ...props }) => {
  const record = useRecordContext() || {};
  const resourceTitle = inflection.titleize(useResourceContext());

  let breadcrum, title, detail;

  if (!record.id) {
    breadcrum = `${resourceTitle} / New ${getResourceName()}`;
    title = `New ${getResourceName()}`;
  } else {
    breadcrum = `${resourceTitle} / ${getRecordName(labelField)}`;
    title = getRecordName(labelField);
    detail = `(ID: ${record.id})`;
  }

  return (
    <Box
      className="RecordTitle ListHeader"
      sx={{ marginBottom: "0" }}
      {...props}
    >
      <Box className="ListSummary" sx={{ marginBottom: "12px" }}>
        {breadcrum}
      </Box>
      <Box
        className="ListTitleWrapper"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Box className="ListTitle">{title}</Box>
          <Box
            className="ListSummary"
            sx={{ marginLeft: "10px", flexShrink: 0 }}
          >
            {detail}
          </Box>
        </Box>
        <Box
          className="ActionsWrapper"
          sx={{ "& .MuiToolbar-root": { minHeight: "auto" } }}
        >
          {actions}
        </Box>
      </Box>
    </Box>
  );
};
