import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "react-admin";

export const GoBackButton = ({ label, icon, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={icon}
      label={label}
      onClick={() => navigate(-1)}
      {...props}
    />
  );
};

GoBackButton.defaultProps = {
  label: "Go Back",
  icon: <ArrowBackIcon />,
};
