import {
  Create,
  CreateBase,
  Edit,
  EditBase,
  SaveButton,
  ShowButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  useNotify,
} from "react-admin";
import { errorHandlingFactory } from "../utils/errors";
import { Settings } from "../utils/settings";
import { Debug } from "./debug";
import { Box, Card, CardContent } from "@mui/material";
import { JsonStyled } from "./jsonStyled";
import { useLocation } from "react-router-dom";
import { Row } from "./row";
import { splitArray } from "../utils/tools";
import { Column } from "./column";
import { GoBackButton } from "./goBackButton";
import { RecordTitle } from "./recordTitle";

const MutatorToolbar = ({ sx, saveEnabled, ...props }) => {
  return (
    <Toolbar sx={{ justifyContent: "space-between", ...sx }} {...props}>
      <SaveButton disabled={!saveEnabled} />
      <GoBackButton size="medium" variant="contained" />
    </Toolbar>
  );
};

const MutatorEditActions = () => (
  <TopToolbar>
    <ShowButton />
  </TopToolbar>
);

export const MyEdit = (props) => <MyMutator {...props} mutatorType="edit" />;
export const MyCreate = (props) => (
  <MyMutator {...props} mutatorType="create" />
);

const MyMutator = ({
  saveEnabled,
  labelField,
  formProps,
  children,
  columns,
  actions,
  ...props
}) => {
  actions =
    actions || (props.mutatorType == "edit" ? <MutatorEditActions /> : null);
  const Mutator = props.mutatorType == "edit" ? Edit : Create;
  const MutatorBase = props.mutatorType == "edit" ? EditBase : CreateBase;

  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  const processedProps = Object.assign(
    {
      mutationMode: Settings.getValue("pessimisticSave")
        ? "pessimistic"
        : "optimistic",
    },
    props
  );

  formProps = {
    warnWhenUnsavedChanges: true,
    toolbar: <MutatorToolbar saveEnabled={saveEnabled} />,
    ...formProps,
  };

  const mutationOptions = { onError, ...props.mutationOptions };
  let { className, sx, style, ...mutatorProps } = processedProps;

  mutatorProps = { ...mutatorProps };

  if (columns > 1) {
    const column_groups = splitArray(children, columns);
    children = (
      <Row>
        {column_groups.map((column, columnIdx) => (
          <Column key={columnIdx}>{column.map((field) => field)}</Column>
        ))}
      </Row>
    );
  }

  return (
    <Box
      className={"MutatorWrapper " + (className || "")}
      sx={sx}
      style={style}
    >
      <MutatorBase {...mutatorProps}>
        <RecordTitle labelField={labelField} actions={actions} />
      </MutatorBase>
      <Mutator
        {...mutatorProps}
        actions={null}
        mutationOptions={mutationOptions}
      >
        <SimpleForm {...formProps}>{children}</SimpleForm>
      </Mutator>
      <Debug foldable={true}>
        <Card>
          <CardContent>
            <h3>Processed Props</h3>
            <JsonStyled data={mutatorProps} />
            <h3>Location Info</h3>
            <JsonStyled data={useLocation()} />
          </CardContent>
        </Card>
      </Debug>
    </Box>
  );
};

MyMutator.defaultProps = {
  redirect: -1,
  mutatorType: "edit",
  columns: 2,
  saveEnabled: true,
};
