import {
  CalendarToday,
  Check,
  EventAvailable,
  Inventory2Outlined,
  Lock,
  LockPerson,
  ThumbDownOffAltRounded,
  WarningAmber,
} from "@mui/icons-material";

export const status_icon = {
  1: {
    label: "Available",
    icon: <Check />,
    hideInDiagram: true,
  },
  2: {
    label: "Occupìed",
    icon: <Inventory2Outlined />,
  },
  3: {
    label: "Reserved",
    icon: <CalendarToday />,
  },
  4: {
    label: "Out of Service",
    icon: <WarningAmber />,
  },
  5: {
    label: "Rented",
    icon: <EventAvailable />,
  },
  6: {
    label: "Declined",
    icon: <ThumbDownOffAltRounded />,
  },
};

export const access_icon = {
  1: {
    label: "Public",
    icon: null,
  },
  2: {
    label: "Restricted",
    icon: <LockPerson />,
  },
  3: {
    label: "Internal use",
    icon: <Lock />,
  },
};
