/**
 * Use on <Admin dataProvider> to process filters before using them
 *
 * Current behaviour: react-admin handles List filter by storing them on the url
 * Problem: If we use a tabbed view to show more than one filtered List on the same page, they all try to use the url to handle filters,
 * so filters for different views get mixed
 * Solution: we qualify the filter names using the entity names 'entity##column', so we can handle filters for different lists on the same url
 */
export const processQualifiedFilters = (resource, params) => {
  if (params.filter) {
    for (const column in params.filter) {
      const [table, rawcol] = column.split("##");

      if (rawcol) {
        if (table === resource) {
          params.filter[rawcol] = params.filter[column];
        }

        delete params.filter[column];
      }
    }
  }
};
