import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { errorIconStyle } from "../../theme/errorIcon";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";

export const AddressInfoTooltip = (props) => {
  const address = props.address || {};

  const empty = [];
  for (const key in address) {
    if (Object.hasOwnProperty.call(address, key)) {
      const element = address[key];
      if (!element) {
        empty.push(key);
      }
    }
  }

  if (!empty.length) return null;

  const icon = <InfoIcon style={errorIconStyle} />;

  const emptyTags = empty.map((val) => <dd key={val}>{val}</dd>);

  const tooltip = (
    <dl>
      <dt>Missing fields</dt>
      {emptyTags}
    </dl>
  );

  return (
    <Tooltip
      title={tooltip}
      placement="right-end"
      className="AddressInfoTooltip"
    >
      {icon}
    </Tooltip>
  );
};

export const LocationInput = (props) => {
  return <MyReferenceInput {...props} secondaryField="address1" />;
};

LocationInput.defaultProps = {
  source: "locationId",
  reference: "locations",
  label: "Location",
};

export const LocationField = () => {
  return (
    <MyReferenceField
      source="locationId"
      reference="locations"
      secondaryField="address1"
    />
  );
};
