import * as React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Typography, IconButton, Modal, Box } from "@mui/material";
import { useRecordContext } from "ra-core";

import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { boxModalStyle } from "../theme/modal";

const NotesModalField = ({ source }) => {
  const record = useRecordContext();
  if (!record || record[source] === null) return null;

  const value = get(record, source);
  const recordName = get(record, "name");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <NoteAltIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {recordName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {value}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

NotesModalField.propTypes = {
  source: PropTypes.string,
};

export default NotesModalField;
