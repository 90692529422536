import { useStore } from "react-admin";
import { Store } from "./store";
import { getConfig } from "../config/packageSetup";

const packageConfig = getConfig();

const app_settings_container = "app_settings";
const app_settings = {
  showOnClick: {
    label: "Lists: Show On Click",
    default: packageConfig.showOnClick,
  },
  pessimisticSave: {
    label: "Save: pessimistic mode",
    default: packageConfig.pessimisticSave,
  },
  "json.max_lines": {
    label: "JSON Fields max lines",
    default: packageConfig["json.max_lines"],
  },
  resetStorage: {
    label: "Restore local settings",
    default: packageConfig.resetStorage,
  },
};

const debug_settings_container = "debug_settings";
const debug_settings = {
  log_level: {
    label: "Log Level",
    default: 0,
  },
};

const prepareSettings = (_settings, container) => {
  const settings = {};

  for (const _setting in _settings) {
    const setup = _settings[_setting];
    const storageKey = container + "." + _setting;
    const [value, setter] = useStore(storageKey, setup.default);

    settings[_setting] = {
      name: _setting,
      label: setup.label,
      value,
      setter,
    };
  }

  return settings;
};

export const Settings = {
  debugging: () => Store.get("debug"),
  debug_level: () => Store.get("RaStore.debug_settings.log_level"),
  get: (_setting) => {
    const settings = prepareSettings(app_settings, app_settings_container);

    if (_setting) {
      return settings[_setting];
    }

    return settings;
  },
  getValue: (_setting) => Settings.get(_setting).value,

  getDebugSettings: () => {
    return prepareSettings(debug_settings, debug_settings_container);
  },
};
