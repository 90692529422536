import { Debug } from "./debug";
import { Dot } from "./dot";
import { JsonStyled } from "./jsonStyled";

export const DebugDotJson = ({ data, sx }) => {
  return (
    <Debug sx={sx}>
      <Dot>
        <JsonStyled data={data} />
      </Dot>
    </Debug>
  );
};
