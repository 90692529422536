import { useResourceContext } from "react-admin";
import { useParams } from "react-router-dom";
import { debug_log } from "./debugLog";

/**
 * Generates a unique store key/list context based on the list container to keep list filters state separated
 * Use on lists that will be used on multiple views
 * https://marmelab.com/react-admin/List.html#storekey
 *
 * @param {*} props
 * @returns
 */
export const getStoreKey = (props) => {
  const resource = useResourceContext();
  const { id } = useParams();
  const storeKey = `${resource}--${id}--${props.resource}`;

  debug_log(`StoreKey: ${storeKey}`);

  return storeKey;
};
