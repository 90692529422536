import ReactDOMServer from "react-dom/server";
import { Path } from "react-konva";

export const KonvaMuiIcon = (props) => {
  let svgString = ReactDOMServer.renderToStaticMarkup(props.MuiIcon);

  /* nasty patch for circle component */
  const circlePath = "M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z";
  const pathStart = '<path d="';
  if (svgString.includes("<circle")) {
    svgString = svgString.replace(pathStart, pathStart + circlePath);
  }

  return <Path {...props} data={svgString} />;
};
