import get from "lodash/get";

export const getErrorMessage = (error) => {
  let detail = get(error, "body.detail") || get(error, "detail") || "";

  if (Array.isArray(detail)) {
    return detail
      .map((err) => {
        const loc = err.loc;

        let source = Array.isArray(loc) ? loc[loc.length - 1] : "";
        source = source ? source + ": " : source;

        let errorIdx =
          Array.isArray(loc) && loc.length > 1 ? loc[loc.length - 2] : "";
        errorIdx = Number.isInteger(errorIdx) ? `Entry #${errorIdx}: ` : "";

        const msg = err.msg || get(err, "ctx.message");

        return `${errorIdx}${source}${msg}`;
      })
      .join(". ");
  }

  return `${error.message} -> ${detail}`;
};

export const errorDisplayConfig = {
  autoHideDuration: 10000,
  multiLine: true,
  type: "error",
};

export const errorHandlingFactory = (notifier) => {
  const onError = (error) => {
    notifier(getErrorMessage(error), errorDisplayConfig);
  };
  return onError;
};
