import {
  TextField,
  SelectField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  useRecordContext,
  TextInput,
} from "react-admin";
import { useParams, Link } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import get from "lodash/get";
import { Debug } from "../ra-lb-tools/components/debug";
import { Settings } from "../ra-lb-tools/utils/settings";
import { Box } from "@mui/material";
import { TowerTimezonedDateField } from "./tower/towerTimezonedDate";
import { TowerField, TowerInput } from "./tower/towers";
import { getGrafanaUri } from "../ra-lb-tools/utils/grafana";

export const SystemIcon = BusinessIcon;

/**
 * TODO:
 * Check for enhancement
 * Currently this needs to be manually synchronized from
 * hal-backend/app/app/models/tower_history.py::class TowerHistoryType(EnumBase)
 */
export const typeChoices = [
  { id: 1, name: "Status Change" },
  { id: 2, name: "Firmware Change" },
  { id: 3, name: "Device Serial Change" },
  { id: 4, name: "Device Model Change" },
  { id: 5, name: "Events Reset: User Reset" },
  { id: 6, name: "Events Reset: Automatic Reset" },
  { id: 7, name: "Event Sync" },
  { id: 8, name: "Error Event" },
];

const towerHistoryFilters = (typeChoices) => [
  <TowerInput
    label="Tower"
    source="towerId"
    alwaysOn
    key={"towerId"}
    filter={{ status_id: 0 }}
  />,
  <SelectInput
    label="Type"
    source="type"
    choices={typeChoices}
    alwaysOn
    key={"type"}
  />,
  <ReferenceInput
    source="user_id"
    label="User"
    reference="users"
    alwaysOn
    allowEmpty
    key={"user_id"}
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
  <TextInput source="device_serial" key={"device_serial"} />,
];

const getLogUri = (requestInfo) => {
  const request_id = requestInfo.id;
  const expr = `|= \`${request_id}\``;
  const time = new Date(requestInfo.timestamp + "Z");
  const delta = 30 * 60 * 1000;
  const from = time.getTime() - delta;
  const to = time.getTime() + delta;

  return getGrafanaUri(expr, from, to);
};

const LogLink = () => {
  const record = useRecordContext();
  const requestInfo = get(record, "new.request");

  if (!(requestInfo && requestInfo.id && requestInfo.timestamp)) {
    return null;
  }

  const logUri = getLogUri(requestInfo);

  return (
    <Link to={logUri} target="_blank">
      Log
    </Link>
  );
};

const types_with_summary = [7, 8];

export const SummaryField = ({ record }) => {
  let sx = {
    fontWeight: "bolder",
  };

  record = record || useRecordContext();
  if (!types_with_summary.includes(record.type)) {
    return null;
  }

  let summary = record.summary || "";
  summary = summary.toLowerCase();

  switch (summary) {
    case "success":
      sx.color = "green";
      break;
    case "failed":
      sx.color = "red";
      break;
    case "mixed":
      sx.color = "orange";
      break;
    case "empty":
      sx.color = "blue";
      break;

    default:
      break;
  }

  let detail = null;
  const error_types_data = get(record, "data.error_types", {}) || {};
  const error_types = Object.keys(error_types_data);
  if (summary == "failed" && error_types.length == 1) {
    detail = (
      <Box>
        ({error_types[0]}/{get(record, "data.detail.events.first")}-
        {get(record, "data.detail.events.last")}/
        {get(record, "data.next_event.before")})
      </Box>
    );
  } else if (record.type == 8) {
    record.summary = get(record, "data.error.description");
    sx.color = "red";
  }

  return (
    <>
      <TextField record={record} source="summary" sortable={false} sx={sx} />
      {detail}
    </>
  );
};

export const TowerHistoryList = (props) => {
  const title = "Tower History";
  const resource = "tower-history";

  return (
    <MyList
      title={title}
      resource={resource}
      filters={towerHistoryFilters(typeChoices)}
      dataGridProps={{
        rowClick: null,
      }}
      sort={{
        field: "id",
        order: "DESC",
      }}
      {...props}
    >
      <TextField source="id" />
      <TowerField source="towerId" />
      <TowerTimezonedDateField source="timestamp" showTime />
      <SelectField source="type" choices={typeChoices} />
      <SummaryField source="summary" />
      <JsonField source="data" sortable={false} />
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>
      {Settings.debugging() && (
        <Debug label="Log">
          <LogLink />
        </Debug>
      )}
    </MyList>
  );
};

export const TowerHistoryListView = () => {
  const { towerId } = useParams();

  return <TowerHistoryList towerId={towerId} />;
};
