import { useRecordContext } from "react-admin";
import get from "lodash/get";

import { JsonStyled } from "./jsonStyled";
import { Settings } from "../utils/settings";

export const JsonField = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  const value = get(record, props.source);
  const maxLines =
    typeof props.maxLines !== "undefined"
      ? props.maxLines
      : Settings.getValue("json.max_lines");

  const _props = Object.assign({}, props, {
    maxLines,
  });

  return <JsonStyled data={value} {..._props} />;
};

JsonField.defaultProps = {};
