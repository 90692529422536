import * as React from "react";
import { useRecordContext } from "react-admin";
import GridActionButton from "../ra-lb-tools/components/buttonGridAction";
import DeleteIcon from "@mui/icons-material/Delete";

const CancelReservationButton = (props) => {
  const record = useRecordContext();
  const url = `/reservations/${record.id}/cancel`;
  const label = "Cancel";

  return (
    <GridActionButton
      url={url}
      label={label}
      startIcon={<DeleteIcon />}
      color="error"
      {...props}
    />
  );
};

export default CancelReservationButton;
