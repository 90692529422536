/**
 * Raw local storage access without hooks, use anywhere
 */
export const Store = {
  set: (key, val) => {
    localStorage.setItem(key, JSON.stringify(val));
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  flip: (key) => {
    Store.set(key, !Store.get(key));
  },
};
