import { Rect, Text } from "react-konva";
import { LockerRender } from "./LockerRender";
import { Ruler } from "./Ruler";
import { styleSchema } from "./style";
import { coord } from "./coord";
import range from "lodash/range";
import { getMaxRows, getRowsByColumn } from "./tools";

export const getLockersFrame = (towerFrame) => {
  const padding = {
    top: styleSchema.towerPaddingPerc.v * towerFrame.size?.height,
    bottom: styleSchema.towerPaddingPerc.v * towerFrame.size?.height,
    left: styleSchema.towerPaddingPerc.h * towerFrame.size?.width,
    right: styleSchema.towerPaddingPerc.h * towerFrame.size?.width,
  };

  return {
    pos: {
      x: towerFrame.pos?.x + padding.left,
      y: towerFrame.pos?.y + padding.top,
    },
    size: {
      width: towerFrame.size?.width - padding.left - padding.right,
      height: towerFrame.size?.height - padding.top - padding.bottom,
    },
  };
};

export const TowerFace = ({
  x,
  y,
  width,
  height,
  numColumns,
  lockers,
  types,
  lockerNameGenerator,
  options,
  ...rest
}) => {
  if (!types) return null;

  const towerFrame = {
    pos: {
      x,
      y,
    },
    size: {
      width,
      height,
    },
    padding: 5,
  };
  const lockersFrame = getLockersFrame(towerFrame);

  const maxCols = numColumns;
  const maxRows = getMaxRows(lockers, types);

  const baseLockerSize = {
    width: lockersFrame.size.width / maxCols,
    height: lockersFrame.size.height / maxRows,
  };

  let rowsByColumn = Object.assign(
    {},
    ...range(1, parseInt(numColumns) + 1).map((k) => ({ [k]: 0 }))
  );
  rowsByColumn = Object.assign(
    {},
    rowsByColumn,
    getRowsByColumn(lockers, types)
  );
  const freeSpacePlaceholders = [];
  for (const col in rowsByColumn) {
    const free = maxRows - rowsByColumn[col];
    if (free) {
      freeSpacePlaceholders.push({
        colPosition: parseInt(col),
        rowPosition: rowsByColumn[col] + 1,
        offsetPosition: 0,
        width: 1,
        height: free,
        lockerType: 1,
        ...styleSchema.locker.freeSpace,
        type: 1,
      });
    }
  }

  const lockersToRender = lockers.concat(freeSpacePlaceholders);

  let renderedLockers = [];
  lockersToRender.forEach((spec, index) => {
    renderedLockers = renderedLockers.concat(
      <LockerRender
        key={index}
        {...{
          x: lockersFrame.pos.x,
          y: lockersFrame.pos.y,
          spec,
          types,
          baseLockerSize,
          index,
          lockerNameGenerator,
          options,
          ...rest,
        }}
      />
    );
  });

  return (
    <>
      <Rect
        className={"TowerFrame"}
        {...{ x, y, width, height }}
        {...styleSchema.towerFace}
      ></Rect>
      {options.proportional && (
        <Text
          text="1:1"
          x={coord.x.right(towerFrame, { width: 15 })}
          y={coord.y.top(towerFrame)}
        />
      )}
      <Rect
        className={"LockerOpacityBackround"}
        {...{
          x: lockersFrame.pos.x,
          y: lockersFrame.pos.y,
          width: lockersFrame.size.width,
          height: lockersFrame.size.height,
        }}
        {...styleSchema.opacityBackround}
      ></Rect>
      {renderedLockers}
      {["tower", "all"].includes(options.ruler) && (
        <Ruler
          frame={lockersFrame}
          numColumns={numColumns}
          numRows={maxRows}
          options={options}
          inside={false}
        />
      )}
    </>
  );
};
