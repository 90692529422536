import { Card, CardContent } from "@mui/material";
import { Button, Title } from "react-admin";
import { Settings } from "../utils/settings";
import { getUnsourcedInputs } from "../components/unsourcedInputs";
import { ClickToCommand } from "../components/clickToCommand";
import get from "lodash/get";
import { Store } from "../utils/store";

const commands = {
  332211: () => {
    console.log("Switching debug mode");
    Store.flip("debug");
    window.location.reload();
  },
};

export const AppSettings = () => {
  const setups = Settings.get();
  return (
    <Card>
      <Title title="App Settings" />
      <CardContent sx={{ "> *": { marginBottom: "10px" } }}>
        {getUnsourcedInputs(setups)}
        <ClickToCommand
          parser={(e) => get(e, "target.value")}
          commands={commands}
        >
          <Button value={1} label="1" />
          <Button value={2} label="2" />
          <Button value={3} label="3" />
        </ClickToCommand>
      </CardContent>
    </Card>
  );
};
