import { Layout, AppBar, ToggleThemeButton, defaultTheme } from "react-admin";
import { createTheme, Box, Typography } from "@mui/material";
import { blue, grey, lime, yellow, orange, red } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { getEnvStyling } from "../ra-lb-tools/utils/tools";
import { Menu } from "./menu";
import { EnvSwitcher } from "../ra-lb-tools/components/envSwitcher";

const fieldStyle = {
  defaultProps: {
    variant: "outlined",
    size: "medium",
    fullWidth: true,
  },
  styleOverrides: {
    root: {
      "& .MuiInputBase-formControl": {
        borderRadius: "5px",
      },
      "& input, textarea, .MuiSelect-select": {
        padding: "16.5px 14px",
      },
      "& label:not(.MuiFormLabel-filled):not(.RaArrayInput-label)": {
        transform: "translate(16px, 14px) scale(1)",
      },
    },
  },
};

const baseTheme = {
  ...defaultTheme,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  shape: {
    borderRadius: 0,
  },
  sidebar: {
    width: 260, // The default value is 240
    closedWidth: 64, // The default value is 55
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: fieldStyle,
    MuiFormControl: fieldStyle,
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#272727",
          "*": {
            color: "#EEEEEE !important",
          },
          "#text": {
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            letterSpacing: "0.07874999940395355px",
            textAlign: "left",
            paddingLeft: "21px",
          },
          ".RaMenuItemLink-active:not(.AccordionMenuSwitch)": {
            color: "#EEEEEE",
            backgroundColor: "#0000008A",
          },
          ".MuiMenuItem-root": {
            height: 44,
          },
          ".MuiListItemIcon-root": {
            padding: "0 22px",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "medium",
      },
      styleOverrides: {
        root: {
          background: "#F7EA48",
          color: "#1B1B1B",
          borderRadius: "5px",
          padding: "8px 20px",
        },
      },
    },
  },
};

export const jiTheme = createTheme({
  ...baseTheme,
  palette: {
    // type: 'dark',
    primary: {
      main: blue[700],
    },
    secondary: {
      main: blue[900],
    },
    testing: {
      main: alpha(orange[600], 0.3),
      light: alpha(orange[600], 0.3),
      dark: alpha(orange[600], 0.3),
      contrastText: "#000",
    },
    background: {
      default: grey[200],
    },
    specialColors: {
      testing: alpha(orange[600], 0.3),
      oos: alpha(red[600], 0.3),
      highlight: alpha(yellow[100], 0.7),
    },
  },
  components: {
    ...baseTheme.components,
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: "#FFFFFF !important",
          borderBottom: "1px solid #E0E0E0",
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          ".MuiToolbar-root:not(.RaBulkActionsToolbar-toolbar)": {
            background: "#FFFFFF !important",
          },
          ".MuiToolbar-root form input[value]:not([value='']) ~ fieldset": {
            backgroundColor: "lightgoldenrodyellow",
          },
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          "& .RaTabbedShowLayout-content": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          padding: "36px 52px !important",
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          "& .RaLabeled-label": {
            color: "#000000",
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "dark",
    primary: {
      main: yellow[500],
    },
    secondary: {
      main: lime[500],
    },
    background: {
      default: "#232323",
      paper: "#272626",
    },
    testing: {
      main: alpha(orange[600], 0.3),
      light: alpha(orange[600], 0.3),
      dark: alpha(orange[600], 0.3),
      contrastText: "#fff",
    },
    specialColors: {
      testing: alpha(orange[200], 0.2),
      oos: alpha(red[200], 0.2),
    },
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    ...baseTheme.components,
    RaList: {
      styleOverrides: {
        root: {
          ".MuiToolbar-root form input[value]:not([value='']) ~ fieldset": {
            backgroundColor: "lightslategrey",
          },
        },
      },
    },
  },
});

const MyAppBar = (props) => {
  const env_styling = getEnvStyling();
  const env_label = env_styling.label && (
    <span style={{ textTransform: "uppercase" }}> ({env_styling.label})</span>
  );
  const env_sx = env_styling.barSx;

  return (
    <AppBar {...props} sx={env_sx}>
      <Box flex="1">
        <Typography
          variant="h6"
          id="react-admin-title"
          sx={{ display: "inline-block" }}
        ></Typography>
        {env_label}
      </Box>
      <EnvSwitcher />
      <ToggleThemeButton lightTheme={jiTheme} darkTheme={darkTheme} />
    </AppBar>
  );
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={Menu} />;

export default MyLayout;
