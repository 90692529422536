export const styleSchema = {
  cube: {
    faceFill: "rgb(160,160,160)",
    faceStroke: "rgb(230,230,230)",
  },
  towerFace: {
    fill: "rgb(190, 190, 190)",
    stroke: "rgb(230,230,230)",
    strokeWidth: 2,
  },
  towerPaddingPerc: {
    h: 13 / 460,
    v: 35 / 510,
  },
  opacityBackround: {
    fill: "black",
  },
  locker: {
    default: {
      opacity: 1,
      strokeWidth: 3,
      strokeColor: "rgb(145,145,145)",
      textColor: "black",
      iconColor: "blue",
    },
    backgroundByAccess: {
      default: "lightskyblue",
      1: "rgb(191,189,188)",
      2: "bisque",
      3: "darksalmon",
    },
    oos: "palevioletred",
    highlight: {
      opacity: 0.8,
      strokeWidth: 3,
      textColor: "white",
      iconColor: "white",
    },
    activeShield: {
      opacity: 0.1,
      textOpacity: 0.1,
    },
    prettyBorder: {
      h1: "rgb(145,145,145)",
      h2: "rgb(90,90,90)",
      h3: "rgb(242,242,242)",
      v1: "rgb(212,212,212)",
    },
    freeSpace: {
      label: "Available",
      fill: "grey",
    },
  },
  iconPadding: 4,
  labeledArrow: {
    pointerLength: 5,
    pointerWidth: 5,
  },
};
