import { Chip } from "@mui/material";
import { WithRecord } from "react-admin";

export const ColoredOptionChipField = () => {
  return (
    <WithRecord
      render={(option) => <Chip label={option.name} color={option.color} />}
    />
  );
};
