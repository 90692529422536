import { useState, useRef, useEffect } from "react";
import { Admin, CustomRoutes, Resource, localStorageStore } from "react-admin";
import Keycloak from "keycloak-js";
import { Route } from "react-router-dom";
import {
  keycloakAuthProvider,
  raKeycloakOptions,
} from "./core/keycloakAuthProvider";
import { httpClient } from "./core/httpClient";
import { apiDataProvider } from "./core/apiProvider";
import Dashboard from "./views/Dashboard";
import MyLayout from "./theme/theme";
import { Loading } from "./components/customLoading";
import {
  UserIcon,
  UserEdit,
  UserCreate,
  UserShow,
  UserPage,
} from "./views/users";
import {
  LocationIcon,
  LocationList,
  LocationEdit,
} from "./views/locations/locations";
import LocationCreate, {
  LocationBulkCreate,
} from "./views/locations/locationCreate";
import {
  SystemIcon,
  SystemList,
  SystemEdit,
  SystemCreate,
  SystemShow,
} from "./views/systems";
import {
  TowerIcon,
  TowerList,
  TowerEdit,
  TowerCreate,
  TowerBulkCreate,
} from "./views/tower/towers";
import { TowerShow } from "./views/tower/TowerDetail";
import {
  LockerIcon,
  LockerList,
  LockerEdit,
  LockerShow,
  LockerKeypadEdit,
  LockerStatusEdit,
} from "./views/lockers";
import {
  ReservationList,
  ReservationIcon,
  ReservationShow,
} from "./views/reservations";
import {
  openLockerTokenIcon,
  openLockerTokenList,
} from "./views/openLockerTokens";
import {
  clientsList,
  clientsView,
  clientsEdit,
  clientsCreate,
  ClientIcon,
} from "./views/clients";
import {
  TowerModelCreate,
  TowerModelEdit,
  TowerModelIcon,
  TowerModelList,
} from "./views/tower_model/TowerModel";
import { TowerHistoryList, TowerHistoryListView } from "./views/towerHistory";

import {
  LockerProviderList,
  LockerProviderCreate,
  LockerProviderEdit,
  LockerProviderShow,
  BusinessViewIcon,
} from "./views/lockerProviders";

import {
  FederationCreate,
  FederationEdit,
  FederationList,
  FederationShow,
} from "./views/federatios";
import { jiTheme } from "./theme/theme";

import { KeyIcon, KeyList } from "./views/keys";
import { TowerModelShow } from "./views/tower_model/TowerModelDetail";
import { LocationShow } from "./views/locations/locationShow";
import { myCustomRoutes } from "./ra-lb-tools/components/customRoutes";
import { LockerHistoryListView } from "./views/lockerHistory";
import { STORE_VERSION } from "./config/settings";
import { ENV, setEnvFavicon } from "./ra-lb-tools/utils/tools";
import { LockerUsageList, LockerUsageListView } from "./views/lockerUsage";
import {
  LockerTypeCreate,
  LockerTypeEdit,
  LockerTypeIcon,
  LockerTypeList,
  LockerTypeShow,
} from "./views/lockerTypes";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationIcon,
  OrganizationList,
  OrganizationShow,
} from "./views/billing/organizations";
import { initialize } from "./ra-lb-tools/config/packageSetup";
import {
  PlanCreate,
  PlanIcon,
  PlanList,
  PlanShow,
} from "./views/billing/plans";
import {
  ProductCreate,
  ProductIcon,
  ProductList,
  ProductShow,
} from "./views/billing/products";
import {
  PlanSubscriptionCreate,
  PlanSubscriptionIcon,
  PlanSubscriptionList,
  PlanSubscriptionShow,
} from "./views/billing/plan_subsciptions";
import { PriceIcon, PriceList, ProductPriceList } from "./views/billing/prices";
import CommandsList from "./views/commands";
import EventsList from "./views/events";
import {
  AttachMoney,
  BugReportOutlined,
  MoreHoriz,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { DeviceIcon, DeviceList, DeviceShow } from "./views/devices";
import { DeviceHistoryIcon, DeviceHistoryList } from "./views/deviceHistory";

const App = () => {
  initialize({
    menuGroups: [
      { label: "More", sortOrder: 51, icon: MoreHoriz },
      { label: "billing", sortOrder: 41, icon: AttachMoney },
      { label: "legacy", icon: WorkOutlineOutlined, debug: true },
      { label: "debug", icon: BugReportOutlined, debug: true },
    ],
  });

  setEnvFavicon();

  const [keycloak, setKeycloak] = useState(undefined);
  const authProvider = useRef(undefined);
  const dataProvider = useRef(undefined);

  useEffect(() => {
    const initKeyCloakClient = async () => {
      const keycloakClient = new Keycloak(ENV().KEYCLOAK_CONFIG);
      await keycloakClient.init(ENV().KEYCLOAK_INIT_OPTIONS);

      authProvider.current = keycloakAuthProvider(
        keycloakClient,
        raKeycloakOptions
      );
      dataProvider.current = apiDataProvider(
        ENV().API_URL,
        httpClient(keycloakClient)
      );
      setKeycloak(keycloakClient);
    };
    if (!keycloak) {
      initKeyCloakClient();
    }
  }, [keycloak]);

  if (!keycloak) return <Loading />;

  return (
    <Admin
      layout={MyLayout}
      dashboard={Dashboard}
      theme={jiTheme}
      authProvider={authProvider.current}
      dataProvider={dataProvider.current}
      disableTelemetry
      requireAuth
      store={localStorageStore(STORE_VERSION)}
    >
      {(permissions) => (
        <>
          {permissions === "admin" ? (
            <>
              <Resource
                name="towers"
                list={TowerList}
                show={TowerShow}
                edit={TowerEdit}
                create={TowerCreate}
                icon={TowerIcon}
              >
                <Route
                  path=":towerId/history"
                  element={<TowerHistoryListView />}
                />
              </Resource>
              <Resource
                name="users"
                list={UserPage}
                edit={UserEdit}
                create={UserCreate}
                show={UserShow}
                icon={UserIcon}
              />
              <Resource
                name="locations"
                list={LocationList}
                edit={LocationEdit}
                create={LocationCreate}
                show={LocationShow}
                icon={LocationIcon}
              />
              <Resource
                name="systems"
                list={SystemList}
                edit={SystemEdit}
                create={SystemCreate}
                show={SystemShow}
                icon={SystemIcon}
              />
              <Resource
                name="clients"
                list={clientsList}
                show={clientsView}
                edit={clientsEdit}
                create={clientsCreate}
                icon={ClientIcon}
              />
              <Resource
                name="organizations"
                list={OrganizationList}
                edit={OrganizationEdit}
                create={OrganizationCreate}
                show={OrganizationShow}
                icon={OrganizationIcon}
                options={{
                  group: "billing",
                }}
              />
              <Resource
                name="products"
                list={ProductList}
                show={ProductShow}
                create={ProductCreate}
                icon={ProductIcon}
                options={{
                  group: "billing",
                }}
              >
                <Route
                  path=":productId/prices"
                  element={<ProductPriceList />}
                />
              </Resource>
              <Resource
                name="prices"
                list={PriceList}
                icon={PriceIcon}
                recordRepresentation={(record) =>
                  `${record.product_data && record.product_data.name} ${
                    record.unit_amount
                  } ${record.currency} ${record.billing_scheme}`
                }
                options={{
                  group: "billing",
                }}
              />
              <Resource
                name="plans"
                list={PlanList}
                create={PlanCreate}
                show={PlanShow}
                icon={PlanIcon}
                options={{
                  group: "billing",
                }}
              />
              <Resource
                name="plan-subscriptions"
                list={PlanSubscriptionList}
                create={PlanSubscriptionCreate}
                show={PlanSubscriptionShow}
                icon={PlanSubscriptionIcon}
                options={{
                  group: "billing",
                }}
              />
              <Resource
                name="tower-models"
                list={TowerModelList}
                show={TowerModelShow}
                create={TowerModelCreate}
                edit={TowerModelEdit}
                icon={TowerModelIcon}
                options={{
                  group: "More",
                }}
              />
              <Resource
                name="locker-providers"
                list={LockerProviderList}
                create={LockerProviderCreate}
                edit={LockerProviderEdit}
                show={LockerProviderShow}
                icon={BusinessViewIcon}
                options={{
                  group: "More",
                }}
              />
              <Resource
                name="federations"
                list={FederationList}
                create={FederationCreate}
                edit={FederationEdit}
                show={FederationShow}
                options={{
                  group: "More",
                }}
              />
              <Resource
                name="devices"
                list={DeviceList}
                show={DeviceShow}
                icon={DeviceIcon}
                options={{
                  group: "More",
                }}
              />
              <Resource
                name="lockers"
                list={LockerList}
                edit={LockerEdit}
                icon={LockerIcon}
                show={LockerShow}
                options={{
                  group: "legacy",
                }}
              >
                <Route
                  path=":lockerId/history"
                  element={<LockerHistoryListView />}
                />
                <Route
                  path=":lockerId/usage"
                  element={<LockerUsageListView />}
                />
                <Route path=":id/keypad" element={<LockerKeypadEdit />} />
                <Route path=":id/status" element={<LockerStatusEdit />} />
              </Resource>
              <Resource
                name="keys"
                list={KeyList}
                icon={KeyIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="reservations"
                list={ReservationList}
                icon={ReservationIcon}
                show={ReservationShow}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="open-locker-tokens"
                list={openLockerTokenList}
                icon={openLockerTokenIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="locker-usage"
                list={LockerUsageList}
                icon={openLockerTokenIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="commands"
                list={CommandsList}
                icon={openLockerTokenIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="events"
                list={EventsList}
                icon={openLockerTokenIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="tower-history"
                list={TowerHistoryList}
                icon={openLockerTokenIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="locker-types"
                list={LockerTypeList}
                edit={LockerTypeEdit}
                create={LockerTypeCreate}
                show={LockerTypeShow}
                icon={LockerTypeIcon}
                options={{
                  group: "legacy",
                }}
              />
              <Resource
                name="device-history"
                list={DeviceHistoryList}
                icon={DeviceHistoryIcon}
                options={{
                  group: "legacy",
                }}
              />
              <CustomRoutes>
                {myCustomRoutes()}
                <Route
                  path="/locations/bulk-create"
                  element={<LocationBulkCreate />}
                />
                <Route
                  path="/towers/bulk-create"
                  element={<TowerBulkCreate />}
                />
              </CustomRoutes>
            </>
          ) : null}
        </>
      )}
    </Admin>
  );
};

export default App;
