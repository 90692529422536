import { TextField, SelectField, DateField, SelectInput } from "react-admin";
import { useParams } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";

export const SystemIcon = BusinessIcon;

/**
 * TODO:
 * Check for enhancement
 * Currently this needs to be manually synchronized from
 * hal-backend/app/app/models/locker_history.py::class LockerHistoryType(EnumBase)
 */
export const typeChoices = [
  { id: 1, name: "Status Change" },
  { id: 2, name: "Name Change" },
  { id: 3, name: "Assigned" },
  { id: 4, name: "Released" },
  { id: 5, name: "Reassigned" },
];

const lockerHistoryFilters = (typeChoices) => [
  <SelectInput
    label="Type"
    source="type"
    choices={typeChoices}
    alwaysOn
    key={"history##type"}
  />,
];

export const LockerHistoryList = (props) => {
  const title = "Locker History";
  const resource = "lockerHistory";
  const lockerId = props.lockerId;

  const filter = { lockerId };

  return (
    <MyList
      title={title}
      resource={resource}
      filter={filter}
      filters={lockerHistoryFilters(typeChoices)}
      dataGridProps={{
        rowClick: null,
      }}
      sort={{
        field: "id",
        order: "DESC",
      }}
    >
      <TextField source="id" />
      <MyReferenceField source="lockerId" reference="lockers" />
      <DateField source="timestamp" showTime />
      <SelectField source="type" choices={typeChoices} />
      <JsonField source="formattedData" />
    </MyList>
  );
};

export const LockerHistoryListView = () => {
  const { lockerId } = useParams();

  return <LockerHistoryList lockerId={lockerId} />;
};
