import set from "lodash/set";
import { JsonStyled } from "../components/jsonStyled";
import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";

export const Status = () => {
  const data = {};
  const keys = Object.keys(localStorage);

  keys.forEach((key) => {
    const element = JSON.parse(localStorage.getItem(key));
    key = key.replaceAll(/--/g, ".");

    set(data, key, element);
  });

  return (
    <Card>
      <Title title="Status" />
      <CardContent>
        <JsonStyled data={data} />
      </CardContent>
    </Card>
  );
};
