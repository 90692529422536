import { Box } from "@mui/material";
import { Settings } from "../utils/settings";
import { debugInfo } from "../theme/styles";
import { useState } from "react";
import { FieldInput } from "./unsourcedInputs";

export const Debug = (props) => {
  if (!Settings.debugging()) {
    return null;
  }

  const { foldable, children, ...rest } = props;
  const [unfolded, setUnfolded] = useState(false);

  return (
    <Box sx={debugInfo} {...rest} className="Debug">
      <Box className={"DebugHeader"}>
        {foldable && (
          <FieldInput
            value={unfolded}
            setter={setUnfolded}
            label={"Debug Info"}
          />
        )}
      </Box>
      {(!foldable || unfolded) && (
        <Box className={"DebugContent"}>{children}</Box>
      )}
    </Box>
  );
};
