import { Stack } from "@mui/material";
import { LabeledAll } from "./labeledAll";

export const LabeledColumn = (props) => {
  return (
    <Stack direction="column">
      <LabeledAll>{props.children}</LabeledAll>
    </Stack>
  );
};
