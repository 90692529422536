import {
  AcUnit,
  AnalyticsOutlined,
  Api,
  ForumOutlined,
  GitHub,
  HelpOutlineOutlined,
  HexagonOutlined,
  Launch,
  PeopleOutline,
  PublishedWithChanges,
  ShoppingCartOutlined,
  ViewComfyOutlined,
} from "@mui/icons-material";
import { MyMenu } from "../ra-lb-tools/components/myMenu";
import { Box } from "@mui/material";
import { MenuItem } from "../ra-lb-tools/components/MenuItem";
import { AccordionMenu } from "../ra-lb-tools/components/accordionMenu";
import { ENV } from "../ra-lb-tools/utils/tools";
import { getGrafanaUri } from "../ra-lb-tools/utils/grafana";

const externalSectionData = [
  {
    to: "https://docs.harborlockers.com/",
    leftIcon: <ViewComfyOutlined />,
    primaryText: "Documentation",
  },
  {
    to: "https://store.harborlockers.com/",
    leftIcon: <ShoppingCartOutlined />,
    primaryText: "Store",
  },
  {
    to: "https://harborlockers.com/changelog/",
    leftIcon: <PublishedWithChanges />,
    primaryText: "Changelogs",
  },
  {
    to: "https://community.harborlockers.com/",
    leftIcon: <ForumOutlined />,
    primaryText: "Forums",
  },

  {
    to: "https://harborlockers.com/submit-a-ticket/",
    leftIcon: <HelpOutlineOutlined />,
    primaryText: "Support Ticket",
  },
  {
    to: "https://status.harborlockers.com/",
    leftIcon: <AnalyticsOutlined />,
    primaryText: "Platform Status",
  },
];

const externalResourcesData = [
  {
    to: "http://admin.harborlockers.localhost/",
    leftIcon: <HexagonOutlined />,
    primaryText: "LOCAL",
    debug: true,
    strictCase: true,
  },
  {
    to:
      ENV().KEYCLOAK_CONFIG.url +
      (ENV().KEYCLOAK_CONFIG.url.endsWith("/") ? "" : "/") +
      "admin",
    leftIcon: <PeopleOutline />,
    primaryText: "Keycloak",
  },
  {
    to: ENV().API_URL.split("/").slice(0, -1).join("/") + "/docs",
    leftIcon: <Api />,
    primaryText: "API",
    strictCase: true,
  },
  {
    to: "https://gitlab.com/luxer-one/harborlockers/hal-admin",
    leftIcon: <GitHub />,
    primaryText: "Repo",
    strictCase: true,
  },
  {
    to: getGrafanaUri(),
    leftIcon: <AcUnit />,
    primaryText: "Grafana",
    strictCase: true,
  },
];

const ExternalSection = () => {
  return (
    <AccordionMenu title={"External links"} icon={Launch}>
      <Box>
        {externalSectionData.map((data, idx) => (
          <MenuItem
            key={idx}
            {...data}
            RightIcon={<Launch />}
            target="_blank"
          />
        ))}
      </Box>
    </AccordionMenu>
  );
};

const ExternalResources = () => {
  return (
    <AccordionMenu title={"Dev resources"} icon={Launch} debug={true}>
      <Box>
        {externalResourcesData.map((data, idx) => (
          <MenuItem
            key={idx}
            {...data}
            RightIcon={<Launch />}
            target="_blank"
          />
        ))}
      </Box>
    </AccordionMenu>
  );
};

export const Menu = () => {
  return (
    <MyMenu>
      <ExternalResources />
      <ExternalSection />
    </MyMenu>
  );
};
