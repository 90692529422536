import { Box } from "@mui/material";
import { Menu } from "react-admin";
import capitalize from "lodash/capitalize";
import { Debug } from "./debug";

export const MenuItem = ({
  RightIcon,
  primaryText,
  debug,
  strictCase,
  ...rest
}) => {
  const MenuText = (
    <Box className="MenuText" sx={{ display: "flex", flexGrow: 1 }}>
      <span style={{ marginRight: "auto" }}>
        {strictCase ? primaryText : capitalize(primaryText)}
      </span>
      {RightIcon}
    </Box>
  );

  return debug ? (
    <Debug>
      <Menu.Item primaryText={MenuText} {...rest} />
    </Debug>
  ) : (
    <Menu.Item primaryText={MenuText} {...rest} />
  );
};
