import makeStyles from "@mui/styles/makeStyles";

export const useRemoveStyles = makeStyles({
  switchBase: {
    "&$checked": {
      transform: "translateX(0px)",
    },
    "&$checked + $track": {
      opacity: 0,
    },
    "&:not($checked)": {
      color: "orange",
    },
  },
  track: {
    opacity: 0,
  },
  checked: {},
});
