import { Box, Chip } from "@mui/material";
import {
  LinearProgress,
  RecordContextProvider,
  useListContext,
  useRecordContext,
  WithRecord,
} from "react-admin";

export const ListCount = ({ children }) => {
  const listContext = useListContext();
  const record = useRecordContext() || {};

  if (listContext.isLoading) {
    return <LinearProgress />;
  }

  if (listContext.error) {
    return <Box>Error: {listContext.error.message}</Box>;
  }

  const count = listContext.data.length;

  children = children || (
    <WithRecord render={(data) => <Chip label={data._count} />} />
  );

  return (
    <RecordContextProvider value={{ ...record, _count: count }}>
      {children}
    </RecordContextProvider>
  );
};
