import * as React from "react";
import { Fragment } from "react";
import { useMutation } from "react-query";
import { useNotify, Button, useDataProvider, useRefresh } from "react-admin";

const GridActionButton = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const mutator = function () {
    return dataProvider
      .customCommand(props.url, notify, props.method, props.successMessage)
      .then(refresh);
  };

  const { mutate, isLoading } = useMutation(mutator);

  const handleClick = (event) => {
    event.stopPropagation();
    mutate();
  };

  return (
    <Fragment>
      <Button
        label={props.label}
        onClick={handleClick}
        color="inherit"
        size="small"
        disabled={isLoading}
        {...props}
      />
    </Fragment>
  );
};

GridActionButton.defaultValues = {
  successMessage: "Success!",
  method: "POST",
  label: "Dooo it!",
  onChange: () => null,
};

export default GridActionButton;
