import * as React from "react";
import { Fragment } from "react";
import { useMutation } from "react-query";
import {
  useNotify,
  useRecordContext,
  Button,
  useDataProvider,
} from "react-admin";

const ImportRemoteLockersButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.importRemoteLockers(record.id, notify)
  );

  const handleClick = () => {
    notify("Starting lockers import");
    mutate();
  };
  return (
    <Fragment>
      <Button
        label="import lockers"
        onClick={handleClick}
        color="inherit"
        size="small"
        disabled={isLoading}
      />
    </Fragment>
  );
};

export default ImportRemoteLockersButton;
