import { Box } from "@mui/material";
import { useState } from "react";
import { debug_log } from "../utils/debugLog";

export const ClickToBuffer = (props) => {
  const [buffer, setBuffer] = useState("");

  const onClick = (e) => {
    const parsed = props.parser(e);
    if (typeof parsed === "undefined") {
      return;
    }

    const newBuffer = (buffer + parsed).slice(-props.bufferLimit);

    debug_log(`ClickToBuffer: ${newBuffer}`);

    setBuffer(props.processor(newBuffer, props.callback));
  };

  return (
    <Box className="ClickToBuffer" onClick={onClick}>
      {props.children}
    </Box>
  );
};

ClickToBuffer.defaultProps = {
  bufferLimit: 10,
  callback: () => null,
};
