import {
  TextField,
  ReferenceField,
  TextInput,
  SelectField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberField,
  useNotify,
  useTheme,
  useDataProvider,
  TabbedShowLayout,
  TopToolbar,
  EditButton,
  Button,
  useRecordContext,
  Link,
  BulkUpdateWithConfirmButton,
  useGetIdentity,
} from "react-admin";
import { useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  errorDisplayConfig,
  getErrorMessage,
} from "../ra-lb-tools/utils/errors";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { useState, useEffect } from "react";
import { LocationInput } from "./locations/components";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { ReservationList } from "./reservations";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyEdit } from "../ra-lb-tools/components/myMutator";
import { openLockerTokenList as OpenLockerTokenList } from "./openLockerTokens";
import { LockerHistoryList } from "./lockerHistory";
import { LockerUsageList } from "./lockerUsage";
import { MyBooleanField as BooleanField } from "../components/myBooleanField";
import { LockerStateList } from "./lockerState";
import CommandsList from "./commands";
import EventsList from "./events";
import { Column } from "../ra-lb-tools/components/column";
import { Row } from "../ra-lb-tools/components/row";
import { Box, IconButton } from "@mui/material";
import { status_icon } from "./tower_render/lockerIcons";
import get from "lodash/get";
import { GoBackButton } from "../ra-lb-tools/components/goBackButton";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";

export const LockerIcon = LockIcon;

export const accessModeChoices = [
  { id: 1, name: "Public" },
  { id: 2, name: "Restricted" },
  { id: 3, name: "Internal Use" },
];

const lockerFilters = (statusChoices) => [
  <TextInput label="Tower Id" source="lockers##tower_id" alwaysOn key={1} />,
  <SelectInput
    label="Status"
    source="lockers##status_id"
    choices={statusChoices}
    alwaysOn
    key={2}
  />,
  <LocationInput source="lockers##tower__location_id" allowEmpty key={3} />,
  <BooleanInput
    label="Is low locker"
    source="lockers##is_low_locker"
    key={4}
  />,
  <ReferenceInput
    source="lockers##assigned_user_id"
    label="Assigned to"
    reference="users"
    allowEmpty
    key={3}
  >
    <AutocompleteInputFluid
      label="Assigned to"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
];

const rowStyle = (record, palette) => {
  return {
    backgroundColor:
      record.statusId == 4
        ? palette.specialColors.oos
        : palette.background.default,
  };
};

const BulkActionButtons = ({ statusChoices, currentUserId }) => {
  const sx = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    position: "absolute",
    top: "0",
    textAlign: "left",
    padding: "10px",
    alignItems: "baseline",
    border: "1px solid black",
    "> *": {
      padding: "5px 0 !important",
      width: "100%",
      justifyContent: "flex-start !important",
    },
  };

  return (
    <Box sx={sx}>
      {statusChoices.map((st) => (
        <BulkUpdateWithConfirmButton
          key={st.id}
          label={"Set " + st.name}
          data={{
            status_id: st.id,
            assignedUserId: st.isAssignable ? currentUserId : null,
          }}
          icon={status_icon[st.id]?.icon}
        />
      ))}
    </Box>
  );
};

const StatusField = (props) => {
  const record = useRecordContext();
  const sx = { display: "flex", alignItems: "center" };

  return (
    <Box sx={sx}>
      {status_icon[record.statusId]?.icon}
      <TextField {...props} sx={{ marginLeft: "5px" }} />
      <EditStatusButton iconOnly={true} />
    </Box>
  );
};

export const LockerList = (props) => {
  const theme = useTheme();
  const palette = theme[0].palette;

  const statusChoices = getStatusChoices();
  const currentUserId = get(useGetIdentity(), "data.id");

  return (
    <Row>
      <Column>
        <MyList
          {...props}
          resource={props.resource}
          filters={lockerFilters(statusChoices)}
          dataGridProps={{
            rowStyle: (record) => rowStyle(record, palette),
            bulkActionButtons: (
              <BulkActionButtons
                statusChoices={statusChoices}
                currentUserId={currentUserId}
              />
            ),
          }}
        >
          <TextField source="id" />
          <TextField source="name" />
          <StatusField source="status.name" label="Status" sortable={false} />
          <TextField source="type.name" label="Type" sortable={false} />
          <SelectField source="accessModeId" choices={accessModeChoices} />
          <BooleanField source="isLowLocker" />
          <NumberField source="physicalId" label="Port" />
          <ReferenceField source="towerId" reference="towers" link="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="towerId" label="Tower Id"></TextField>
          <TextField source="keypadCode" />
          <BooleanField source="keypadCodePersists" />
        </MyList>
      </Column>
    </Row>
  );
};

LockerList.defaultProps = {
  resource: "lockers",
};

const getStatusChoices = (verbose) => {
  const formatStatus = (st) => {
    st.name = st.name.replace(/_/g, " ").toLowerCase();
    st.name = st.name.charAt(0).toUpperCase() + st.name.slice(1);

    if (verbose) {
      const assignable_label = st.isAssignable
        ? "assignable"
        : "non assignable";
      st.name += ` (${assignable_label})`;
    }

    return st;
  };

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [statusChoices, setStatusChoices] = useState([]);

  useEffect(() => {
    !statusChoices.length &&
      dataProvider
        .retrieveLockerStatuses()
        .then((res) => setStatusChoices(res["data"].map(formatStatus)))
        .catch((err) =>
          notify(
            `Error getting locker status: ${getErrorMessage(err)}`,
            errorDisplayConfig
          )
        );
  }, []);

  return statusChoices;
};

const validateLockerAssignability = (values, statusChoices) => {
  const errors = {};

  const statusId = values.statusId;
  const status = statusChoices.find((choice) => choice["id"] == statusId);
  const non_assignable = status && !status.isAssignable;

  if (non_assignable && values.assignedUserId) {
    errors.assignedUserId =
      "Non assignable status, assigned user should be empty";
  }

  if (!(non_assignable || values.assignedUserId)) {
    errors.assignedUserId = "ra.validation.required";
  }

  console.log(errors);

  return errors;
};

export const LockerEdit = (props) => {
  const statusChoices = getStatusChoices(true);

  return (
    <MyEdit
      {...props}
      formProps={{
        validate: (values) =>
          validateLockerAssignability(values, statusChoices),
        shouldUnregister: true,
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <BooleanInput source="isLowLocker" />
      <TextInput source="towerId" disabled />
      <TextInput source="type.name" disabled />
      <SelectInput
        source="accessModeId"
        choices={accessModeChoices}
        optionValue="id"
        optionText="name"
      />
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput source="assignedUserId" reference="users" allowEmpty>
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
    </MyEdit>
  );
};

const KeypadCodePersists = () => {
  const record = useRecordContext();
  if (!record) return null;
  const allowed = record.accessModeId != 1;

  return allowed && <BooleanInput source="keypadCodePersists" />;
};

export const LockerKeypadEdit = (props) => {
  return (
    <MyEdit
      {...props}
      formProps={{
        shouldUnregister: true,
      }}
      mutationOptions={{ meta: { customSegment: "keypad" } }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" disabled />
      <KeypadCodePersists source="keypadCodePersists" />
      <TextInput source="keypadCode" />
    </MyEdit>
  );
};

const EditKeypadButton = () => {
  const record = useRecordContext();

  if (!record) return null;
  const path = `/lockers/${record.id}/keypad`;

  return (
    <Button
      component={Link}
      to={path}
      label={"Keypad"}
      onClick={(e) => e.stopPropagation()}
    >
      <EditIcon />
    </Button>
  );
};

export const LockerStatusEdit = (props) => {
  const statusChoices = getStatusChoices(true);

  return (
    <MyEdit
      {...props}
      formProps={{
        validate: (values) =>
          validateLockerAssignability(values, statusChoices),
        shouldUnregister: true,
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" disabled />
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput source="assignedUserId" reference="users" allowEmpty>
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
    </MyEdit>
  );
};

const EditStatusButton = ({ iconOnly, ...props }) => {
  const record = useRecordContext();

  if (!record) return null;
  const path = `/lockers/${record.id}/status`;

  const Component = iconOnly ? IconButton : Button;

  return (
    <Component
      component={Link}
      to={path}
      label={"Status"}
      onClick={(e) => e.stopPropagation()}
      title={"Edit status"}
      sx={{ marginLeft: "5px" }}
      {...props}
    >
      <EditIcon />
    </Component>
  );
};

const LockerShowActions = () => (
  <TopToolbar>
    <EditButton />
    <EditKeypadButton />
    <EditStatusButton />
    <GoBackButton size="small" variant="text" />
  </TopToolbar>
);

export const LockerShow = (props) => {
  const { id } = useParams();
  const statusChoices = getStatusChoices(true);

  return (
    <TitledShow {...props} actions={<LockerShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <BooleanField source="isLowLocker" />
              <ReferenceField source="towerId" reference="towers" link="show">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="type.name" />
            </LabeledColumn>
            <LabeledColumn>
              <SelectField source="statusId" choices={statusChoices} />
              <SelectField source="accessModeId" choices={accessModeChoices} />
              <ReferenceField
                source="assignedUserId"
                reference="users"
                allowEmpty
                link="show"
              >
                <TextField source="email" />
              </ReferenceField>
              <TextField source="keypadCode" />
              <BooleanField source="keypadCodePersists" />
            </LabeledColumn>
          </Row>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Reservations" path="reservations">
          <ReservationList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Open Locker Token"
          path="open-locker-tokens"
        >
          <OpenLockerTokenList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History" path="history">
          <LockerHistoryList lockerId={id} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Usage" path="usage">
          <LockerUsageList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="States" path="states">
          <LockerStateList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Commands" path="commands">
          <CommandsList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Events" path="events">
          <EventsList filter={{ locker_id: parseInt(id) }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
