import { Stack } from "@mui/material";

export const Column = ({ sx, ...props }) => {
  return (
    <Stack
      className="Column"
      direction="column"
      sx={{ flex: 1, ...sx }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};
