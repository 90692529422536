import {
  TextField,
  TabbedShowLayout,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  TextInput,
  DateField,
  WithRecord,
  useGetList,
  Link,
} from "react-admin";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Box, Chip, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { TowerList } from "./tower/towers";
import { MyList } from "../ra-lb-tools/components/myList";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import { Memory } from "@mui/icons-material";
import { UniqueReferenceManyField } from "../ra-lb-tools/components/uniqueReferenceManyField";
import { DeviceHistoryList } from "./deviceHistory";
import { ListCount } from "../ra-lb-tools/components/listCount";

export const DeviceIcon = Memory;

const filters = [<TextInput source="serial_number" alwaysOn key={1} />];

const getChipColor = (tower) => {
  if (tower.statusId == 4) {
    return "error";
  } else if (tower.isForTest) {
    return "testing";
  } else {
    return "default";
  }
};

const TowerCount = () => {
  return (
    <WithRecord
      render={(tower) =>
        tower._count > 1 ? (
          <Box>
            <Box sx={{ fontWeight: "bold", margin: "5px 0" }}>
              Board Towers:
            </Box>
            <Link to={`/devices/${tower.deviceSerial}/show/towers-current`}>
              <Chip label={tower._count} color="error" clickable />
            </Link>
          </Box>
        ) : null
      }
    />
  );
};

export const DeviceCurrentTowersCount = (props) => (
  <ReferenceManyField
    className="DeviceCurrentTowers"
    source="serialNumber"
    reference="towers"
    target="device_serial"
    filter={{ status_id: 0 }}
    sortable={false}
    {...props}
  >
    <ListCount>
      <TowerCount />
    </ListCount>
  </ReferenceManyField>
);

export const DeviceCurrentTowers = (props) => {
  return (
    <ReferenceManyField
      className="DeviceCurrentTowers"
      source="serialNumber"
      reference="towers"
      target="device_serial"
      filter={{ status_id: 0 }}
      sortable={false}
      {...props}
    >
      <SingleFieldList linkType="show">
        <WithRecord
          render={(record) => (
            <ChipField source="name" color={getChipColor(record)} />
          )}
        />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

export const DeviceHistoryTowers = () => (
  <UniqueReferenceManyField
    className="DeviceHistoryTowers"
    label="Tower history"
    source="serialNumber"
    reference="device-history"
    target="serial_number"
    uniqueField="towerId"
    sortable={false}
  >
    <ListCount>
      <WithRecord
        render={(device) => (
          <Link to={`/devices/${device.id}/show/history`}>
            <Chip label={device._count} clickable />
          </Link>
        )}
      />
    </ListCount>
  </UniqueReferenceManyField>
);

export const DeviceList = (props) => (
  <MyList {...props} resource="devices" filters={filters}>
    <TextField source="id" />
    <TextField source="serialNumber" />
    <DeviceCurrentTowers label="Current tower" />
    <DeviceHistoryTowers label="Tower history" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);

export const DeviceShow = (props) => {
  const id = useParams()["id"];

  if (isNaN(Number(id))) {
    return <DeviceShowBySerial {...props} />;
  } else {
    return <DeviceShowView {...props} />;
  }
};

export const DeviceShowBySerial = (props) => {
  const serial = useParams()["id"];

  const { data, isLoading } = useGetList("devices", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter: { serial_number: serial },
  });

  if (isLoading) {
    return null;
  }

  const record = data[0];

  return <DeviceShowView id={record.id} {...props} />;
};

export const DeviceShowView = (props) => {
  return (
    <TitledShow labelField="serialNumber" {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row" className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="serialNumber" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
            <LabeledColumn>
              <DeviceCurrentTowers label="Current tower" />
              <DeviceHistoryTowers label="Tower history" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Current Towers" path="towers-current">
          <WithRecord
            render={(device) => (
              <TowerList
                filter={{ device_serial: device.serialNumber, status_id: 0 }}
              />
            )}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History" path="history">
          <WithRecord
            render={(device) => (
              <DeviceHistoryList
                filter={{ serial_number: device.serialNumber }}
              />
            )}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
