import { v4 as uuidv4 } from "uuid";
import { accessModeChoices } from "../lockers";

export const lockerName = function* (prefix = "", start = 0) {
  while (true) {
    start++;
    yield prefix + start;
  }
};

export const applyLockerUpdates = (newLockers, setLockers, onChange) => {
  setLockers(newLockers);
  onChange(newLockers);
};

export const getType = (spec, types) =>
  (types || []).find(
    (type) => type.id === spec.type || type.id === spec.type.id
  );

const get_column_lockers = (lockers, column) =>
  lockers.filter((locker) => locker.colPosition == column);

const getLockerBottom = (locker, types) =>
  locker.rowPosition +
  (getType(locker, types) ? getType(locker, types).relHeight : 0);

export const getNextRow = (lockers, column, types) => {
  lockers = get_column_lockers(lockers, column);

  const bottoms = lockers.map((locker) => getLockerBottom(locker, types));

  return lockers.length > 0 ? Math.max(...bottoms) : 1;
};

const getLockerWidth = (locker, types) =>
  getType(locker, types) ? getType(locker, types).relWidth : 1;

const getCurrentRowData = (lockers, column, types) => {
  lockers = get_column_lockers(lockers, column);
  if (!lockers.length) return 1;

  //good enough for now
  const current_row_position = Math.max(
    ...lockers.map((locker) => locker.rowPosition)
  );
  const row = {
    rowPosition: current_row_position,
    lockers: lockers.filter(
      (locker) => locker.rowPosition == current_row_position
    ),
  };

  row.usedSpace = row.lockers.reduce(
    (sum, locker) => sum + getLockerWidth(locker, types),
    0
  );
  row.availableSpace = 1 - row.usedSpace;

  return row;
};

//
export const addLocker = (
  lockers,
  setLockers,
  onChange,
  column,
  onDuplicateError,
  types
) => {
  if (getDuplicatePhysicalIds(lockers).length > 0) {
    onDuplicateError();
    return;
  }

  let physicalId =
    lockers.length > 0
      ? Math.max(...lockers.map((locker) => locker.physicalId)) + 1
      : 1;

  const lastLockersInColumn = get_column_lockers(lockers, column).pop() || {};
  const type = lastLockersInColumn.type || 1;

  let position = {
    rowPosition: getNextRow(lockers, column, types),
    offsetPosition: 0,
  };

  const rowdata = getCurrentRowData(lockers, column, types);
  if (getLockerWidth({ type }, types) <= rowdata.availableSpace) {
    position = {
      rowPosition: rowdata.rowPosition,
      offsetPosition: rowdata.usedSpace,
    };
  }

  const locker = {
    physicalId: physicalId,
    colPosition: column,
    id: uuidv4(),
    rowPosition: position.rowPosition,
    offsetPosition: position.offsetPosition,
    type: type,
    accessModeId: lastLockersInColumn.accessModeId,
  };

  const newLockers = [...lockers, locker];
  newLockers.sort((a, b) => a.physicalId - b.physicalId);

  applyLockerUpdates(newLockers, setLockers, onChange);
};
//
export const removeLocker = (lockers, setLockers, onChange, id) => {
  const newLockers = lockers.filter((locker) => locker.id !== id);

  applyLockerUpdates(newLockers, setLockers, onChange);
};

export const getDuplicatePhysicalIds = (lockers) => {
  const physicalIds = lockers.map((locker) => locker.physicalId);
  const duplicates = [];

  physicalIds.forEach((id, index) => {
    if (physicalIds.indexOf(id) !== index && duplicates.indexOf(id) === -1) {
      duplicates.push(id);
    }
  });

  return duplicates;
};

export const buildLockersSpec = (data, lockers) => {
  return lockers.map((locker) => {
    return {
      type: data[`type-${locker.id}`],
      isLowLocker: data[`isLowLocker-${locker.id}`],
      physicalId: data[`physicalId-${locker.id}`],
      name: data[`name-${locker.id}`],
      accessModeId: data[`accessModeId-${locker.id}`],
      colPosition: locker.colPosition,
      rowPosition: data[`rowPosition-${locker.id}`],
      offsetPosition: data[`offsetPosition-${locker.id}`],
    };
  });
};

export const validateFields = (data, lockers) => {
  const errors = {};

  if (!data.name) {
    errors.name = "ra.validation.required";
  }
  if (!data.nameStrategy) {
    errors.nameStrategy = "ra.validation.required";
  }
  if (!data.numColumns) {
    errors.numColumns = "ra.validation.required";
  }

  const lockersSpec = lockers ? buildLockersSpec(data, lockers) : [];
  const duplicatePhysicalIds = getDuplicatePhysicalIds(lockersSpec);

  if (lockers) {
    lockers.forEach((locker) => {
      const currentPhysicalId = data[`physicalId-${locker.id}`];
      if (!currentPhysicalId) {
        errors[`physicalId-${locker.id}`] = "ra.validation.required";
      } else if (duplicatePhysicalIds.includes(currentPhysicalId)) {
        lockers.forEach((otherLocker) => {
          if (data[`physicalId-${otherLocker.id}`] === currentPhysicalId) {
            errors[`physicalId-${otherLocker.id}`] = "Duplicated";
          }
        });
      }
      if (!data[`type-${locker.id}`]) {
        errors[`type-${locker.id}`] = "ra.validation.required";
      }
    });
  }

  return errors;
};

export const getAccessModeName = (accessModeId) => {
  const choice = accessModeChoices.find((choice) => choice.id === accessModeId);
  return choice ? choice.name : "";
};
