import camelCase from "lodash/camelCase";
import { debug_log } from "./debugLog";

/**
 * Removes fixed filters from UI filters
 *
 * @param {*} uiFilters
 * @param {*} fixedFilter
 * @returns
 */
export const getUniqueUIFilters = (uiFilters, fixedFilter) => {
  uiFilters = uiFilters || [];
  const fixedFilters = Object.keys(fixedFilter || {}).map(camelCase);

  const uniqueFilters = [];

  uiFilters.forEach((uiFilter) => {
    const source = camelCase(uiFilter.props.source.split("##").pop());
    if (!fixedFilters.includes(source)) {
      uniqueFilters.push(uiFilter);
    } else {
      debug_log(`UI filters: removed fixed filter '${source}'`);
    }
  });

  return uniqueFilters;
};
