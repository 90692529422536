import { LinearProgress } from "@mui/material";
import { useRef } from "react";
import {
  ReferenceManyField,
  useListContext,
  ListContextProvider,
} from "react-admin";

const WithTransformedListContext = ({ children, transform }) => {
  const listContext = useListContext();
  if (listContext.isLoading) {
    return <LinearProgress />;
  } else {
    const data = transform(listContext.data);
    const transformedContext = { ...listContext, data };
    return (
      <ListContextProvider value={transformedContext}>
        {children}
      </ListContextProvider>
    );
  }
};

WithTransformedListContext.defaultProps = {
  transform: (v) => v,
};

export const UniqueReferenceManyField = ({
  source,
  reference,
  target,
  label,
  uniqueField,
  children,
}) => {
  const uniqueRecords = (data) => {
    const unique = {};

    data.forEach((record) => (unique[record[uniqueField]] = record));

    return Object.values(unique);
  };

  const renderCount = useRef(0);
  renderCount.current += 1;

  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      source={source}
      label={label}
    >
      <WithTransformedListContext transform={uniqueRecords}>
        {children}
      </WithTransformedListContext>
    </ReferenceManyField>
  );
};
