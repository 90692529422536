import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const convertAccessHoursToSimpleTime = (accessHours) => {
  const result = {};
  for (const day of Object.keys(accessHours)) {
    const timeSlots = accessHours[day].map((slot) => ({
      start: slot.start.format("HH:mm"),
      end: slot.end.format("HH:mm"),
    }));
    result[day] = timeSlots;
  }
  return result;
};

export const transformAccessHours = (record) => {
  const transformedAccessHours = {};

  for (const day in record) {
    const transformedDay = record[day].map((timeSlot) => {
      const currentDate = new Date();
      const startTime = `${currentDate.toISOString().split("T")[0]}T${
        timeSlot.start
      }`;
      const endTime = `${currentDate.toISOString().split("T")[0]}T${
        timeSlot.end
      }`;
      return {
        start: dayjs.utc(startTime),
        end: dayjs.utc(endTime),
      };
    });
    transformedAccessHours[day] = transformedDay;
  }

  return transformedAccessHours;
};
