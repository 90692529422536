import React from "react";
import { Line } from "react-konva";

export const getAngleRad = (angleDeg) => (angleDeg * Math.PI) / 180;

export const getOffset = (projectionAngle, depth) => {
  const _projectionAngle = projectionAngle % 360;
  const angleRad = getAngleRad(_projectionAngle);

  return {
    xOffset: depth * Math.cos(angleRad),
    yOffset: depth * Math.sin(angleRad),
  };
};

export const getInnerSize = (projectionAngle, depth, width, height) => {
  const { xOffset, yOffset } = getOffset(projectionAngle, depth);

  return {
    width: width - Math.abs(xOffset),
    height: height - Math.abs(yOffset),
  };
};

export const Cube = ({
  x,
  y,
  width,
  height,
  projectionAngle,
  depth,
  faceFill,
  faceStroke,
  children,
}) => {
  const _projectionAngle = projectionAngle % 360;

  const { xOffset, yOffset } = getOffset(projectionAngle, depth);
  const xOffsetAbs = Math.abs(xOffset);
  const yOffsetAbs = Math.abs(yOffset);

  const isTopFace = _projectionAngle >= 0 && _projectionAngle <= 180;
  const isLeftFace = _projectionAngle >= 90 && _projectionAngle <= 270;
  const isRightFace = _projectionAngle <= 90 || _projectionAngle >= 270;
  const isBottomFace = _projectionAngle >= 180 && _projectionAngle <= 360;

  const { width: _width, height: _height } = getInnerSize(
    projectionAngle,
    depth,
    width,
    height
  );
  const _x = isLeftFace ? x + xOffsetAbs : x;
  const _y = isTopFace ? y + yOffsetAbs : y;

  return (
    <>
      {isTopFace && (
        <Line
          points={[
            _x,
            _y,
            _x + _width,
            _y,
            _x + _width + xOffset,
            _y - yOffset,
            _x + xOffset,
            _y - yOffset,
          ]}
          closed
          fill={faceFill}
          stroke={faceStroke}
        />
      )}

      {isBottomFace && (
        <Line
          points={[
            _x,
            _y + _height,
            _x + _width,
            _y + _height,
            _x + _width + xOffset,
            _y + _height - yOffset,
            _x + xOffset,
            _y + _height - yOffset,
          ]}
          closed
          fill={faceFill}
          stroke={faceStroke}
        />
      )}

      {isLeftFace && (
        <Line
          points={[
            _x,
            _y,
            _x + xOffset,
            _y - yOffset,
            _x + xOffset,
            _y + _height - yOffset,
            _x,
            _y + _height,
          ]}
          closed
          fill={faceFill}
          stroke={faceStroke}
        />
      )}

      {isRightFace && (
        <Line
          points={[
            _x + _width,
            _y,
            _x + _width + xOffset,
            _y - yOffset,
            _x + _width + xOffset,
            _y + _height - yOffset,
            _x + _width,
            _y + _height,
          ]}
          closed
          fill={faceFill}
          stroke={faceStroke}
        />
      )}

      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          x: _x,
          y: _y,
          width: _width,
          height: _height,
        })
      )}
    </>
  );
};

Cube.defaultProps = {
  depth: 50,
  projectionAngle: 45,
  faceFill: "grey",
  faceStroke: "black",
};
