import { getEnvStyling } from "./tools";

const grafanaUri = "https://grafana.harborlockers.com/explore";

const getPane = (
  expr = "",
  from = "now-6h",
  to = "now",
  namespace = "",
  datasource_uid = "P8E80F9AEF21F6940"
) => {
  namespace = namespace || getEnvStyling().grafana.namespace;

  return {
    zvr: {
      datasource: datasource_uid,
      queries: [
        {
          refId: "A",
          expr: `{namespace="${namespace}"} ${expr}`,
          queryType: "range",
          datasource: {
            type: "loki",
            uid: datasource_uid,
          },
          editorMode: "builder",
        },
      ],
      range: {
        from: `${from}`,
        to: `${to}`,
      },
      panelsState: {
        logs: {
          visualisationType: "logs",
        },
      },
    },
  };
};

export const getGrafanaUri = (expr, from, to, namespace, datasource_uid) =>
  `${grafanaUri}?schemaVersion=1&orgId=1&panes=` +
  JSON.stringify(getPane(expr, from, to, namespace, datasource_uid));
