import { Labeled } from "react-admin";

export const LabeledAll = (props) => {
  const labeled = props.children.map((child, idx) => (
    <Labeled key={idx} className="RaLabeled">
      {child}
    </Labeled>
  ));

  return <>{labeled}</>;
};
