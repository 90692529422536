import { Arrow, Rect, Text } from "react-konva";
import { coord } from "./coord";
import { styleSchema } from "./style";

const backgroundPadding = 3;
const pixXChar = 5;
const charHeight = 10;

export const LabeledArrow = ({
  text,
  textBackground,
  rotation,
  points,
  ...props
}) => {
  const [x1, y1, x2, y2] = points;
  const width = x2 - x1;
  const height = y2 - y1;

  const text_frame = {
    size: {
      width: (text.length + 1) * pixXChar,
      height: charHeight,
    },
    pos: {
      x: x1,
      y: y1,
    },
  };

  text_frame.offset = {
    x: x1 - coord.x.center({ x: x1, width }, text_frame.size),
    y:
      y1 -
      coord.y.top({
        y: y1,
        height,
      }) +
      text_frame.size.height / 2,
  };

  const bgnd_frame = {
    pos: {
      x: text_frame.pos.x - backgroundPadding,
      y: text_frame.pos.y - backgroundPadding,
    },
    size: {
      width: text_frame.size.width + 2 * backgroundPadding,
      height: text_frame.size.height + 2 * backgroundPadding,
    },
    offset: {
      x: text_frame.offset.x,
      y: text_frame.offset.y + backgroundPadding / 2,
    },
  };

  if (rotation) {
    const radians = rotation * (Math.PI / 180);

    const x2_rotated =
      x1 + width * Math.cos(radians) - height * Math.sin(radians);
    const y2_rotated =
      y1 + width * Math.sin(radians) + height * Math.cos(radians);

    points = [x1, y1, x2_rotated, y2_rotated];
  }

  return (
    <>
      <Arrow {...props} points={points} {...styleSchema.labeledArrow} />
      <Rect
        x={bgnd_frame.pos.x}
        y={bgnd_frame.pos.y}
        width={bgnd_frame.size.width}
        height={bgnd_frame.size.height}
        fill={textBackground || styleSchema.towerFace.fill}
        rotation={rotation}
        offset={bgnd_frame.offset}
      />
      <Text
        text={text}
        x={text_frame.pos.x}
        y={text_frame.pos.y}
        rotation={rotation}
        offset={text_frame.offset}
      />
    </>
  );
};
