import { magic } from "./TowerRender";
import { LabeledArrow } from "./LabeledArrow";
import { objectMap } from "../../ra-lb-tools/utils/tools";

const arrowHeight = 10;

export const Ruler = ({
  frame,
  sizeLabel,
  numColumns,
  numRows,
  inside,
  options,
  stroke,
}) => {
  const offset = inside ? -arrowHeight : arrowHeight;

  const containerH = {
    x: frame.pos.x,
    y: frame.pos.y - offset,
    width: frame.size.width,
    height: arrowHeight,
  };

  const containerV = {
    x: frame.pos.x + frame.size.width + offset,
    y: frame.pos.y,
    width: frame.size.height,
    height: arrowHeight,
  };

  sizeLabel = sizeLabel || {
    width: numColumns * magic.relToInch,
    height: (numRows * magic.relToInch) / magic.locker1x1AspectRatio,
  };

  if (options?.unit == "cm") {
    sizeLabel = objectMap({
      obj: sizeLabel,
      valueCallback: (val) => Math.round(val * 2.54) + " cm",
    });
  } else {
    sizeLabel = objectMap({
      obj: sizeLabel,
      valueCallback: (val) => val + '"',
    });
  }

  return (
    <>
      <LabeledArrow
        points={[
          containerH.x,
          containerH.y,
          containerH.x + frame.size.width,
          containerH.y,
        ]}
        stroke={stroke}
        pointerAtBeginning={true}
        pointerAtEnd={true}
        text={sizeLabel.width}
      />
      <LabeledArrow
        points={[
          containerV.x,
          containerV.y,
          containerV.x + containerV.width,
          containerV.y,
        ]}
        stroke={stroke}
        pointerAtBeginning={true}
        pointerAtEnd={true}
        text={sizeLabel.height}
        rotation={90}
      />
    </>
  );
};

Ruler.defaultProps = {
  stroke: "black",
};
