import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DayRangeModal from "./accessHoursRangeInput";
import dayjs from "dayjs";

const AccessHoursInput = ({ accessHours, setAccessHours }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDayRangeApply = (selectedDays, startTime, endTime) => {
    const updatedAccessHours = { ...accessHours };
    for (const day of selectedDays) {
      if (!updatedAccessHours[day]) {
        updatedAccessHours[day] = [];
      }

      updatedAccessHours[day].push({
        start: dayjs(startTime),
        end: dayjs(endTime),
      });
    }

    setAccessHours(updatedAccessHours);
  };

  const handleAccessHourChange = (day, index, field, newValue) => {
    const updatedAccessHours = { ...accessHours };
    updatedAccessHours[day][index][field] = dayjs(newValue);
    setAccessHours(updatedAccessHours);
  };

  const addTimeSlot = (day) => {
    setAccessHours((prevAccessHours) => {
      const updatedAccessHours = { ...prevAccessHours };
      updatedAccessHours[day].push({ start: dayjs(), end: dayjs() });
      return updatedAccessHours;
    });
  };

  const removeTimeSlot = (day, index) => {
    setAccessHours((prevAccessHours) => {
      const updatedAccessHours = { ...prevAccessHours };
      updatedAccessHours[day].splice(index, 1);
      return updatedAccessHours;
    });
  };
  const clearTimeSlots = (day) => {
    setAccessHours((prevAccessHours) => ({
      ...prevAccessHours,
      [day]: [],
    }));
  };

  return (
    <div>
      <h3>Access Hours</h3>
      <Button
        onClick={handleOpenModal}
        variant="contained"
        style={{ marginBottom: "10px" }}
      >
        Add Access Hours
      </Button>
      <DayRangeModal
        open={modalOpen}
        onClose={handleCloseModal}
        onApply={handleDayRangeApply}
      />
      <Grid container spacing={3}>
        {Object.keys(accessHours).map((day) => (
          <Grid item xs={12} key={day}>
            <div
              style={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                marginBottom: "5px",
              }}
            >
              <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
              <Button onClick={() => clearTimeSlots(day)} color="error">
                Clear
              </Button>
              {accessHours[day].length === 0 ? (
                <p style={{ color: "grey", fontStyle: "italic" }}>
                  No access hours added
                </p>
              ) : (
                accessHours[day].map((hour, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="from"
                        value={hour.start}
                        onChange={(newValue) => {
                          const time = newValue.format("HH:mm");
                          handleAccessHourChange(day, index, "start", time);
                        }}
                      />
                      <TimePicker
                        label="to"
                        value={hour.end}
                        onChange={(newValue) => {
                          const time = newValue.format("HH:mm");
                          handleAccessHourChange(day, index, "end", time);
                        }}
                      />
                    </LocalizationProvider>
                    <Button
                      onClick={() => removeTimeSlot(day, index)}
                      color="warning"
                    >
                      <RemoveCircleIcon />
                    </Button>
                  </div>
                ))
              )}
              <Button
                onClick={() => addTimeSlot(day)}
                style={{ marginTop: "10px" }}
              >
                <AddCircleIcon />
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AccessHoursInput;
