import { TextField, DateField } from "react-admin";
import { useParams } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";

export const SystemIcon = BusinessIcon;

export const LockerUsageList = (props) => {
  const title = "Locker Usage";
  const resource = "locker-usage";
  const lockerId = props.lockerId;

  const filter = props.filter || { lockerId };

  return (
    <MyList
      title={title}
      resource={resource}
      filter={filter}
      sort={{
        field: "id",
        order: "DESC",
      }}
    >
      <TextField source="id" />
      <MyReferenceField source="lockerId" reference="lockers" />
      <DateField source="createdAt" showTime />
      <DateField source="fromDt" showTime />
      <DateField source="toDt" showTime />
      <JsonField source="data" />
      <MyReferenceField
        source="userId"
        reference="users"
        primaryField="email"
      />
    </MyList>
  );
};

export const LockerUsageListView = () => {
  const { lockerId } = useParams();

  return <LockerUsageList lockerId={lockerId} />;
};
