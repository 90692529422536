import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";

export const LockerInput = (props) => {
  return <MyReferenceInput source="lockerId" reference="lockers" {...props} />;
};

export const LockerField = (props) => {
  return <MyReferenceField source="lockerId" reference="lockers" {...props} />;
};
