import { EditButton, Show, ShowBase, TopToolbar } from "react-admin";

import { Box } from "@mui/material";
import { GoBackButton } from "./goBackButton";
import { RecordTitle } from "./recordTitle";

const TitledWrapper = (props) => {
  return <Box sx={{ width: "100%" }}>{props.children}</Box>;
};

const ShowActions = () => (
  <TopToolbar>
    <EditButton />
    <GoBackButton size="small" variant="text" />
  </TopToolbar>
);

export const TitledShow = ({
  labelField,
  className,
  sx,
  style,
  actions,
  ...props
}) => {
  actions = actions || <ShowActions />;
  const recordTitle = <RecordTitle labelField={labelField} actions={actions} />;
  const _sx = { width: "100%" };

  return (
    <Box
      sx={{ ..._sx, ...sx }}
      style={style}
      className={["TitledShowWrapper", className].join(" ")}
    >
      <ShowBase {...props}>{recordTitle}</ShowBase>
      <Show
        component={TitledWrapper}
        className="TitledShow"
        actions={null}
        {...props}
      >
        {props.children}
      </Show>
    </Box>
  );
};
