import * as React from "react";
import { useState, Fragment } from "react";

import { useMutation } from "react-query";
import {
  useDataProvider,
  Confirm,
  useNotify,
  useRecordContext,
  Button,
} from "react-admin";

const RegenerateSecretButton = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.regenerateSecret(record.id)
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
    notify("Secret regenerated successfully!", { type: "success" });
  };

  return (
    <Fragment>
      <Button
        label="regenerate secret"
        onClick={handleClick}
        color="inherit"
        size="medium"
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Regenerate secret"
        content={`Are you sure you want to regenerate secret of client ${record.id}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default RegenerateSecretButton;
