export const STORE_VERSION = "14";
export const listSummary = {
  towers:
    "Towers are a group of lockers with a control board with locks attached",
  users:
    "Service accounts are not associated with any human identity. A user account is an account tied to a human identity",
  systems:
    "Systems are a group of Towers that can be accessed by a particular app.",
  clients: "Clients are used to request information from the Harbor API",
  "tower-models":
    "Tower models are the different shapes and sizes of our Towers.",
  lockers: "A compartment intended for storage that is secured by a lock.",
};
