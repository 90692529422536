import groupBy from "lodash/groupBy";
import { getType } from "../tower_model/lockersHelpers";

export const getMaxRows = (specs, types) => {
  return Math.max(...Object.values(getRowsByColumn(specs, types)));
};

export const getRowsByColumn = (specs, types) => {
  const byColumn = groupBy(
    specs.filter((v) => v.type),
    (el) => el.colPosition
  );
  const rowsByColumn = {};

  for (const col in byColumn) {
    const column = byColumn[col];
    const last = column[column.length - 1];
    const lastType = getType(last, types);
    const lastHeight = lastType ? lastType.relHeight : 0;

    rowsByColumn[col] = last.rowPosition + lastHeight - 1;
  }

  return rowsByColumn;
};
