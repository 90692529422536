import { Route } from "react-router-dom";
import { Settings } from "../utils/settings";
import { useCustomRoutes } from "../config/customRoutes";
import get from "lodash/get";

const customRouteEnabled = (route) =>
  !get(route, "options.debugOnly") || Settings.debugging();

const getEnabledRoutes = () => useCustomRoutes().filter(customRouteEnabled);

export const myCustomRoutes = () => {
  return getEnabledRoutes().map((route, index) => (
    <Route
      path={route.path}
      element={route.component}
      {...route.routeProps}
      key={index}
    />
  ));
};
