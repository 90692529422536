import { useRecordContext } from "react-admin";
import { CopyToClipboardButton } from "./copyToClipboardButton";

export const base64toHex = (b64str) => {
  const bytes = Uint8Array.from(atob(b64str), (c) => c.charCodeAt(0));
  const hexString = Array.from(bytes)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hexString;
};

export const Base64ToHexField = (props) => {
  const record = useRecordContext();
  if (!record) return null;

  const data = record[props.source];
  if (!data) return null;

  const hexString = base64toHex(data);

  return (
    <span title={data}>
      {hexString}
      <CopyToClipboardButton data={hexString} sx={{ marginLeft: "5px" }} />
    </span>
  );
};
