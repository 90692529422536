export const boxModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#272626",
  color: "white",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "5px",
  p: 4,
};
