import { getType } from "../tower_model/lockersHelpers";
import { debug_log } from "../../ra-lb-tools/utils/debugLog";
import { Line, Rect, Text } from "react-konva";
import { AccessRender, LowRender, StatusRender } from "./icons";
import { shieldThreshold } from "./TowerRender";
import { styleSchema } from "./style";
import { Ruler } from "./Ruler";

const getShield = (pId) => Math.ceil(pId / shieldThreshold);
const getShieldLabel = (pId) => getShield(pId) + "-";
const getPId = (pId, withShield) =>
  withShield ? ((pId - 1) % shieldThreshold) + 1 : pId;

const getPIdLabel = (pId, withShield) =>
  "[#" +
  (withShield ? getShieldLabel(pId) : "") +
  getPId(pId, withShield) +
  "]";

const getNameLabel = (name) =>
  name
    .replace(/extra/gi, "xtra")
    .split(" ")
    .map((str) => str.toUpperCase()[0])
    .join("");

export const LockerRender = ({
  x,
  y,
  baseLockerSize,
  spec,
  types,
  index,
  highlightList,
  highlight_key,
  onMouseEnter,
  onMouseLeave,
  lockerNameGenerator,
  onClick,
  options,
}) => {
  const lockerType = getType(spec, types);
  if (!lockerType) {
    debug_log(`locker type not found, phys id: ${spec.physicalId}`);
    return null;
  }

  const renderProps = {
    width: (spec.width || lockerType.relWidth) * baseLockerSize.width,
    height: (spec.height || lockerType.relHeight) * baseLockerSize.height,
    x:
      x +
      (spec.x || spec.colPosition - 1 + spec.offsetPosition) *
        baseLockerSize.width,
    y: y + (spec.y || spec.rowPosition - 1) * baseLockerSize.height,
  };

  if (Object.values(renderProps).some((value) => isNaN(value))) {
    debug_log(`LockerRender: NaN value found phys id: ${spec.physicalId}`);
    return null;
  }

  const name =
    spec.name || (lockerNameGenerator ? lockerNameGenerator.next().value : "");

  const label =
    spec.label ||
    [
      options.type ? getNameLabel(lockerType.name) : "",
      options.name ? name : "",
      options.port ? getPIdLabel(spec.physicalId, options.shield) : "",
    ].join(" ");

  let fillByType =
    styleSchema.locker.backgroundByAccess[spec.accessModeId] ||
    styleSchema.locker.backgroundByAccess.default;

  if (spec.statusId == 4) {
    fillByType = styleSchema.locker.oos;
  }

  const fill = spec.fill || fillByType;

  let style = styleSchema.locker.default;

  if (highlightList.includes(spec[highlight_key])) {
    style = styleSchema.locker.highlight;
  } else if (
    options.highlight_shield &&
    options.highlight_shield != getShield(spec.physicalId)
  ) {
    style = styleSchema.locker.activeShield;
  }

  const textXOffset = -3 * label.length;
  const textYOffset = -5;

  return [
    <Rect
      key={index}
      fill={fill}
      opacity={style.opacity}
      stroke={style.strokeColor}
      strokeWidth={style.strokeWidth}
      x={renderProps.x}
      y={renderProps.y}
      width={renderProps.width}
      height={renderProps.height}
    />,
    <Line
      key={index + "border1"}
      points={[
        renderProps.x,
        renderProps.y,
        renderProps.x + renderProps.width,
        renderProps.y,
      ]}
      stroke={styleSchema.locker.prettyBorder.h1}
      strokeWidth={3}
    />,
    <Line
      key={index + "border2"}
      points={[
        renderProps.x + 10,
        renderProps.y,
        renderProps.x + renderProps.width - 10,
        renderProps.y,
      ]}
      stroke={styleSchema.locker.prettyBorder.h2}
      strokeWidth={3}
    />,
    <Line
      key={index + "border3"}
      points={[
        renderProps.x,
        renderProps.y + 3,
        renderProps.x + renderProps.width,
        renderProps.y + 3,
      ]}
      stroke={styleSchema.locker.prettyBorder.h3}
      strokeWidth={1}
    />,
    <Line
      key={index + "border4"}
      points={[
        renderProps.x + renderProps.width - 3,
        renderProps.y,
        renderProps.x + renderProps.width - 3,
        renderProps.y + renderProps.height,
      ]}
      stroke={styleSchema.locker.prettyBorder.v1}
      strokeWidth={1}
    />,
    <Text
      key={index + "k"}
      text={label}
      x={renderProps.x + renderProps.width / 2 + textXOffset}
      y={renderProps.y + renderProps.height / 2 + textYOffset}
      fill={style.textColor}
      opacity={style.textOpacity ? style.textOpacity : null}
    />,
    <LowRender
      key={index + "low"}
      spec={spec}
      style={style}
      renderProps={renderProps}
    />,
    options.access && (
      <AccessRender
        key={index + "acc"}
        spec={spec}
        style={style}
        renderProps={renderProps}
      />
    ),
    options.status && spec.statusId && (
      <StatusRender
        key={index + "st"}
        spec={spec}
        style={style}
        renderProps={renderProps}
      />
    ),
    <Rect
      key={index + "event"}
      fill={"transparent"}
      stroke={"transparent"}
      x={renderProps.x}
      y={renderProps.y}
      width={renderProps.width}
      height={renderProps.height}
      onMouseEnter={() => onMouseEnter && onMouseEnter(spec)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(spec)}
      onClick={() => onClick && onClick(spec)}
    />,
    ["locker", "all"].includes(options.ruler) && (
      <Ruler
        frame={{
          pos: {
            x: renderProps.x,
            y: renderProps.y,
          },
          size: {
            width: renderProps.width,
            height: renderProps.height,
          },
        }}
        numColumns={spec.width || lockerType.relWidth}
        numRows={spec.height || lockerType.relHeight}
        inside={true}
        options={options}
      />
    ),
  ];
};

LockerRender.defaultProps = {
  highlightList: [],
  highlight_key: "physicalId",
};
