import { Stage, Layer } from "react-konva";
import { useGetList, useRecordContext, useStore } from "react-admin";
import get from "lodash/get";
import { lockerName } from "../tower_model/lockersHelpers";
import { Box } from "@mui/material";
import { DebugDotJson } from "../../ra-lb-tools/components/debugDotJson";
import { useFormContext } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import { Legend } from "./icons";
import { ToolBox } from "./ToolBox";
import { Cube, getInnerSize } from "./Cube";
import { styleSchema } from "./style";
import { getLockersFrame, TowerFace } from "./TowerFace";
import { getMaxRows } from "./tools";

export const magic = {
  relToInch: 15,
  locker1x1AspectRatio: 3,
};

export const shieldThreshold = 24;

const getProportionalCanvasSize = (
  canvasSize,
  options,
  numColumns,
  lockers,
  types
) => {
  const columnWidthPixel =
    getLockersFrame({
      size: getInnerSize(
        options.box_angle,
        options.box_depth,
        canvasSize.width,
        canvasSize.height
      ),
    }).size.width / numColumns;

  let widthProportional = canvasSize.width;
  const rowHeightProportional = columnWidthPixel / magic.locker1x1AspectRatio;
  const totalRows = getMaxRows(lockers, types);
  let heightProportional = rowHeightProportional * totalRows;

  if (heightProportional > canvasSize.height) {
    widthProportional *= canvasSize.height / heightProportional;
    heightProportional = canvasSize.height;
  }

  return {
    width: widthProportional,
    height: heightProportional,
  };
};

const getDefaultOptions = (lockers) => ({
  name: true,
  port: true,
  shield: lockers.length > shieldThreshold ? true : false,
  type: false,
  highlight_shield: 0,
  access: false,
  status: true,
  legend: true,
  ruler: "",
  proportional: true,
  box_angle: 45,
  box_depth: 50,
  unit: "inch",
});

export const TowerRender = ({
  lockers,
  numColumns,
  canvasSize,
  alwaysVisible,
  onAlwaysVisibleChange,
  sx,
  nameStrategy,
  tools,
  header,
  ...rest
}) => {
  const FormContext = useFormContext();
  const getFormValues = FormContext && FormContext.getValues;
  const record = useRecordContext();
  lockers = get(lockers, "length") ? lockers : get(record, "lockersSpec", []);
  const lowLockers = lockers.filter((locker) => locker.isLowLocker);
  const hasStatus = lockers[0] && lockers[0].statusId;
  const shieldCount = Math.ceil(lockers.length / shieldThreshold);

  const [options, setOptions] = useStore(
    "tower-render-options",
    Object.assign({}, getDefaultOptions(lockers), tools)
  );

  numColumns = numColumns || get(record, "numColumns", 1);
  nameStrategy =
    nameStrategy ||
    (getFormValues && getFormValues("nameStrategy")) ||
    get(record, "nameStrategy");

  const { data: types } = useGetList("locker-types");

  const totalSize = window.innerWidth / 3;
  canvasSize = canvasSize || {
    width: totalSize,
    height: totalSize,
  };

  if (!canvasSize.height) {
    canvasSize = {
      width: canvasSize.width,
      height:
        alwaysVisible && options.proportional
          ? window.innerHeight * 0.7
          : canvasSize.width,
    };
  }

  canvasSize = options.proportional
    ? getProportionalCanvasSize(canvasSize, options, numColumns, lockers, types)
    : canvasSize;

  const lockerNameGenerator =
    nameStrategy == "numeric"
      ? lockerName()
      : nameStrategy == "alphanumeric"
      ? lockerName("A-")
      : null;

  const lockerCountSx = {
    display: "flex",
    padding: "5px 0",
  };

  const headerSx = {
    textAlign: "center",
  };

  const headerBoldSx = {
    fontWeight: "bold",
  };

  const baseSx = {
    backgroundColor: "background.default",
    width: "100%",
    paddingRight: "10px",
  };

  return (
    <Box
      className="TowerRender"
      sx={{ ...baseSx, ...sx }}
      width={canvasSize.width}
    >
      <ToolBox
        {...{
          alwaysVisible,
          onAlwaysVisibleChange,
          options,
          setOptions,
          hasStatus,
          shieldCount,
        }}
      />
      <Box sx={headerSx} className={"Header"}>
        {header.map((text, index) => (
          <Box key={index} sx={index === 0 ? headerBoldSx : null}>
            {text}
          </Box>
        ))}
      </Box>
      <Box sx={lockerCountSx} className={"InfoBox"}>
        Lockers: {lockers.length} ({lowLockers.length} low{" "}
        {!lowLockers.length && <WarningIcon style={{ color: "#e4a11b" }} />})
      </Box>
      <Stage
        className={"KonvaStage"}
        width={canvasSize.width}
        height={canvasSize.height}
      >
        <Layer>
          <Cube
            {...{
              x: 0,
              y: 0,
              width: canvasSize.width,
              height: canvasSize.height,
              depth: options.box_depth,
              projectionAngle: options.box_angle,
              ...styleSchema.cube,
            }}
          >
            <TowerFace
              {...{
                numColumns,
                lockers,
                types,
                lockerNameGenerator,
                options,
                ...rest,
              }}
            />
          </Cube>
        </Layer>
      </Stage>
      {options.legend && <Legend sx={{ marginTop: "5px" }} />}
      <DebugDotJson data={lockers} />
    </Box>
  );
};

TowerRender.defaultProps = {
  tools: {},
  header: [],
};
