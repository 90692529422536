import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import Typography from "@mui/material//Typography";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoneIcon from "@mui/icons-material/Done";

const CopyTextField = (props) => {
  const { className, source, emptyText } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      onClick={onCopy}
      style={{ cursor: "pointer" }}
    >
      {value != null ? value : emptyText}
      {copied ? (
        <DoneIcon color="secondary" style={{ marginLeft: 10 }} />
      ) : (
        <FileCopyIcon color="primary" style={{ marginLeft: 10 }} />
      )}
    </Typography>
  );
};

CopyTextField.defaultProps = {
  addLabel: true,
};

export default CopyTextField;
