import { Box } from "@mui/material";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem } from "./MenuItem";
import { Debug } from "./debug";

export const AccordionMenu = ({
  open: openDefault,
  icon,
  title,
  children,
  debug,
  ...rest
}) => {
  const [open, setOpen] = useState(openDefault);

  const MenuIcon = icon;
  const OpenIcon = open ? ExpandLessIcon : ExpandMoreIcon;

  const LeftIcon = MenuIcon || OpenIcon;
  const RightIcon = MenuIcon ? <OpenIcon /> : null;

  const Accordion = (
    <Box className={"AccordionMenu"} {...rest}>
      <MenuItem
        to={window.location.href.split("#").pop()}
        onClick={() => setOpen(!open)}
        primaryText={title}
        leftIcon={<LeftIcon />}
        className="AccordionMenuSwitch"
        RightIcon={RightIcon}
      />
      {open && (
        <Box className={"AccordionMenuItems"} sx={{ padding: "0 0 0 16px" }}>
          {children}
        </Box>
      )}
    </Box>
  );

  return debug ? <Debug>{Accordion}</Debug> : Accordion;
};

AccordionMenu.defaultValues = {
  open: true,
};
