const rules = {
  password: [
    {
      validate: (value) => !!value,
      msg: "ra.validation.required",
      shortCircuit: true,
    },
    {
      validate: (value) => value.length >= 10,
      msg: "Password must contain at least 10 characters",
    },
    {
      validate: (value) => /[a-z]/.test(value),
      msg: "Password must include lowercase characters",
    },
    {
      validate: (value) => /[A-Z]/.test(value),
      msg: "Password must include Upercase characters",
    },
    {
      validate: (value) => /\d/.test(value),
      msg: "Password must include numbers",
    },
    {
      validate: (value) => /[!@#$%^&*]/.test(value),
      msg: "Password must include any of (!@#$%^&*)",
    },
  ],
};

const validateField = (values, field) => {
  const result = {
    valid: true,
    errors: [],
    errorMsg: "",
  };

  const fieldRules = rules[field];
  const fieldValue = values[field];

  if (!fieldRules) {
    return result;
  }

  for (const rule of fieldRules) {
    if (!rule.validate(fieldValue)) {
      result.valid = false;
      result.errors.push(rule.msg);

      if (rule.shortCircuit) {
        break;
      }
    }
  }

  result.errorMsg = result.errors.join(". ");

  return result;
};

const validateUser = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "ra.validation.required";
  }
  if (!values.role) {
    errors.role = "ra.validation.required";
  }

  return errors;
};

export const validateUserCreation = (values, roles) => {
  const errors = validateUser(values);

  if (values.role === roles.SUPERUSER) {
    const passwordValidation = validateField(values, "password");
    if (!passwordValidation.valid) {
      errors.password = passwordValidation.errorMsg;
    }
  } else if (!values.password) {
    errors.password = "ra.validation.required";
  }

  return errors;
};

export const validateUserUpdate = (values) => {
  const errors = validateUser(values);

  return errors;
};
