import { ReferenceField, TabbedShowLayout, TextField } from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { JsonField } from "../../ra-lb-tools/components/jsonField";
import { TowerList } from "../tower/towers";
import { FederationList } from "../federatios";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";

export const LocationShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row" className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="address1" />
              <TextField source="address2" />
              <TextField source="description" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="lat" />
              <TextField source="lon" />
              <TextField source="city" />
              <TextField source="state" />
              <TextField source="zip" />
              <TextField source="country" />
              <TextField source="timezone" />
            </LabeledColumn>
            <LabeledColumn>
              <ReferenceField source="ownerId" reference="users" link="show">
                <TextField source="email" />
              </ReferenceField>
              <TextField source="notes" />
              <JsonField source="accessHours" maxLines={null} />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ location_id: id }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList
            filter={{ location_id: id, status_id: 4 }}
            filters={null}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Federations" path="federations">
          <FederationList filter={{ location_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
