import { colors } from "@mui/material";
import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

export const debugInfo = {
  color: colors["purple"]["400"] + "!important",
  "*": {
    color: colors["purple"]["400"] + "!important",
  },
};

export const customRouteMenu = {
  textTransform: "capitalize",
};

export const labeledBig = {};

export const shakeAnimation = makeStyles(() => ({
  shake: {
    animation: "$description 1s",
    animationIterationCount: "1",
  },
  "@keyframes description": {
    "0%": { transform: "skewX(-15deg)" },
    "5%": { transform: "skewX(15deg)" },
    "10%": { transform: "skewX(-15deg)" },
    "15%": { transform: "skewX(15deg)" },
    "20%": { transform: "skewX(0deg)" },
    "100%": { transform: "skewX(0deg)" },
  },
}));

export const sxSpot = (props) => {
  props = props || {};
  const size = props.size || 25;

  return {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: size,
    width: size,
    height: size,
    backgroundColor: props.bg || red[400],
    color: props.color || "white",
  };
};
