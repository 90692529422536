import { Store } from "../utils/store";

export const SETTINGS = {
  showOnClick: false,
  pessimisticSave: true,
  "json.max_lines": 10,
  listPageSize: 50,
  menuGroups: ["", "legacy"],
  resetStorage: () => {
    console.log("Reseting storage");
    Store.flip("RaStore.version");
    window.location.reload();
  },
};
