import { cloneElement } from "react";
import { debug_log } from "./debugLog";
import inflection from "inflection";
import { objectMap } from "./tools";

const qualifierGlue = "##";

export const qualifyField = (field, qualifier) => {
  if (field.includes(qualifierGlue)) {
    debug_log(`qualifyField: skipping already qualified '${field}'`, 2);
    return field;
  }

  return qualifier + qualifierGlue + field;
};

export const unqualifyField = (field) => field.split(qualifierGlue).pop();
export const unqualifyFilter = (filter) =>
  objectMap({ obj: filter || {}, keyCallback: unqualifyField });

/**
 * Adds filter qualifier to encapsulate filter list
 * Usefull for pages with multiple lists
 *
 * @param {*} filters
 * @param {*} qualifier
 * @returns
 */
export const qualifyFilter = (filters, qualifier) => {
  filters = filters || {};
  const qualified = {};

  Object.keys(filters).map((filterKey) => {
    const quialifiedKey = qualifyField(filterKey, qualifier);

    if (quialifiedKey != filterKey) {
      debug_log(`Qualifing Fixed filter '${filterKey}' for ${qualifier}`);
    }

    qualified[quialifiedKey] = filters[filterKey];
  });

  return qualified;
};

export const qualifyUIFilter = (filters, qualifier) => {
  filters = filters || [];

  const qualified = filters.map((filter) => {
    const source = filter.props.source;
    const qualifiedSource = qualifyField(source, qualifier);

    if (qualifiedSource != source) {
      debug_log(`Qualifing UI filter '${source}' for ${qualifier}`, 2);
      const newProps = {
        source: qualifiedSource,
      };

      if (!filter.props.label) {
        newProps.label = inflection.humanize(source);
      }

      filter = cloneElement(filter, newProps);
    }

    return filter;
  });

  return qualified;
};
