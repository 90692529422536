import { DateField } from "react-admin";
import { objectMap } from "../utils/tools";
import { Box } from "@mui/material";
import { Debug } from "./debug";

const DateBox = ({ tz, label, ...props }) => (
  <Box>
    <span>{label}: </span>
    <DateField {...props} options={{ timeZone: tz }} />
  </Box>
);

export const MultiDateField = ({ timezones, ...props }) => {
  if (!timezones) {
    return <DateField {...props} />;
  }

  const dates = Object.values(
    objectMap({
      obj: timezones,
      valueCallback: (tz, label) => (
        <DateBox {...props} key={tz} tz={tz} label={label} />
      ),
    })
  );

  dates.push(
    <Debug key={"debug"}>
      <DateBox {...props} tz={undefined} label={"YT"} />
    </Debug>
  );

  return <>{dates}</>;
};
