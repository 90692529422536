import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

export const MapSelector = ({
  apiKey,
  handleMapClick,
  clickedLatLng,
  center,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  return (
    <>
      {isLoaded ? (
        <>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={18}
            onClick={handleMapClick}
          >
            {clickedLatLng !== null ? (
              <Marker position={clickedLatLng} />
            ) : null}
          </GoogleMap>
        </>
      ) : null}
    </>
  );
};
