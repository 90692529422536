import {
  TextField,
  TabbedShowLayout,
  DateField,
  TextInput,
  required,
  ReferenceArrayInput,
  ReferenceArrayField,
  AutocompleteArrayInput,
  useResourceDefinition,
  SingleFieldList,
  WithRecord,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Chip, Stack } from "@mui/material";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { PlanSubscriptionList } from "./plan_subsciptions";
import { useParams } from "react-router-dom";
import { AddShoppingCartOutlined } from "@mui/icons-material";

export const PlanIcon = AddShoppingCartOutlined;

const PricesList = () => {
  const { recordRepresentation } = useResourceDefinition({
    resource: "prices",
  });

  const renderChip = (record) => <Chip label={recordRepresentation(record)} />;

  return (
    <ReferenceArrayField source="prices" reference="prices">
      <SingleFieldList linkType={false}>
        <WithRecord render={renderChip} />
      </SingleFieldList>
    </ReferenceArrayField>
  );
};

export const PlanList = (props) => (
  <MyList {...props} resource="plans">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <PricesList label={"Prices"} />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);

export const PlanShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row" className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
              <PricesList label="Prices" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Subscriptions" path="Subscriptions">
          <PlanSubscriptionList filter={{ plan_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const PlanCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <ReferenceArrayInput source="prices" reference="prices">
        <AutocompleteArrayInput
          filterToQuery={(searchText) => ({ product_name: `${searchText}` })}
        />
      </ReferenceArrayInput>
    </Create>
  );
};

export const PlanInput = (props) => {
  return <MyReferenceInput source="planId" reference="plans" {...props} />;
};

export const PlanField = (props) => {
  return <MyReferenceField source="planId" reference="plans" {...props} />;
};
