import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { JsonStyled } from "../components/jsonStyled";
import { ENV } from "../utils/tools";

export const EnvSettings = () => {
  return (
    <Card>
      <Title title="Settings" />
      <CardContent>
        <JsonStyled data={ENV()} />
      </CardContent>
    </Card>
  );
};
