import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";

export const Dot = ({ sxDotFlip, ...props }) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  const sxBaseDot = {
    height: props.size + "px",
    width: props.size + "px",
    borderRadius: props.size / 2 + "px",
  };

  const sxCollapsed = collapsed
    ? Object.assign({}, sxBaseDot, {
        overflow: "hidden",
      })
    : {};

  sxDotFlip = Object.assign(
    {},
    sxBaseDot,
    {
      position: "absolute",
      right: collapsed ? 0 : "50%",
      backgroundColor: grey[500],
      cursor: "pointer",
      zIndex: 100,
    },
    sxDotFlip
  );

  const sxDot = Object.assign({}, { position: "relative" }, sxCollapsed);

  return (
    <Box className="Dot" sx={sxDot}>
      <IconButton
        className="DotFlip"
        sx={sxDotFlip}
        onClick={() => setCollapsed((c) => !c)}
        size="large"
      >
        {collapsed ? <OpenInFull /> : <CloseFullscreen />}
      </IconButton>
      {props.children}
    </Box>
  );
};

Dot.defaultProps = {
  size: 30,
  collapsed: true,
};
