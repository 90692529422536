import { Menu } from "react-admin";
import { ResourceMenuItems } from "../utils/resourceMenuItems";
import { HomeOutlined } from "@mui/icons-material";

export const MyMenu = ({ children }) => (
  <Menu>
    <Menu.DashboardItem primaryText="Home" leftIcon={<HomeOutlined />} />
    <ResourceMenuItems />
    {children}
  </Menu>
);
