import { Box, FormControlLabel, Switch, TextField } from "@mui/material";
import { Button } from "react-admin";

const LabeledSwitch = (props) => {
  const control = <Switch checked={props.value} onChange={props.onChange} />;

  return <FormControlLabel control={control} label={props.label} />;
};

const NumberField = (props) => <TextField type="number" {...props} />;

const fieldInputs = {
  default: {
    input: TextField,
    getter: (event) => event.target.value,
    parser: (v) => v,
  },
  boolean: {
    input: LabeledSwitch,
    getter: (event) => event.target.checked,
  },
  number: {
    input: NumberField,
    parser: parseInt,
  },
  function: {
    input: Button,
  },
};

export const FieldInput = (props) => {
  const type = typeof props.value;
  const typeSetup = Object.assign(
    {},
    fieldInputs.default,
    fieldInputs[type] || {}
  );
  const Input = typeSetup.input;

  const handler = (event) => {
    const value = typeSetup.parser(typeSetup.getter(event));
    props.setter(value);
  };

  return (
    <Box key={props.name} sx={props.sx} className={"FieldInput"}>
      <Input
        label={props.label}
        onChange={handler}
        value={type === "function" ? undefined : props.value}
        onClick={type === "function" ? props.value : undefined}
      />
    </Box>
  );
};

export const getUnsourcedInputs = (setups) => {
  return Object.values(setups).map((setup) => FieldInput(setup));
};
