import { useState, Fragment } from "react";
import { useMutation } from "react-query";
import {
  Confirm,
  useNotify,
  useRecordContext,
  useDataProvider,
  Link,
} from "react-admin";
import { ButtonStrong } from "../../components/buttonStrong";
import { Box } from "@mui/material";

const ResetButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.resetEvents(record.id, notify)
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    notify("Starting the Tower reset");
    mutate();
    setOpen(false);
  };

  const confirm_message = (
    <>
      <Box>
        Are you sure a factory reset was completed on tower: {record.id}?
      </Box>
      <Box>
        Resetting tower events here must be done in unison with a physical tower
        board reset. Resetting tower events here DOES NOT replace a physical
        tower reset.
      </Box>
      <Box>
        Resetting events only here, or at different times from the reset here,
        will result in sync issues that will require a physical visit to the
        tower to resolve.
      </Box>
      <Box>
        Do not do this unless you are absolutely sure. For additional
        information{" "}
        <Link
          target="_blank"
          to="https://docs.harborlockers.com/common_issues.html#factory-reset-without-using-the-app"
        >
          see this
        </Link>
        .
      </Box>
      <Box>The board has been factory reset?</Box>
    </>
  );
  return (
    <Fragment>
      <ButtonStrong
        label="Report tower reset"
        onClick={handleClick}
        color="inherit"
        size="small"
        sx={{ flexShrink: 0 }}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="WARNING! READ THIS"
        content={confirm_message}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{
          "*": {
            paddingBottom: "5px",
          },
        }}
        confirm={"YES"}
        cancel={"NO"}
        confirmColor="green"
      />
    </Fragment>
  );
};

export default ResetButton;
